import React from 'react'
import './inbox.scss'
import { useParams } from 'react-router-dom';
import HostNav from '../../../components/hostNav/HostNav';

const Inbox = () => {

    const {id} = useParams()

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="inbox">
          <div className="notification">
            <p>notification</p>
          </div>
          <div className="mailBox">
            <p>inbox</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inbox
