import React from 'react'
import './tours.scss'
import useFetch from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';

const Tours = () => {

  const navigate = useNavigate()
     const { data, loading, error } = useFetch(
       "/hotels/countByCity?cities=berlin,madrid,london"
     );

const handleClick = (id) => {
  const validIds = ["Bali", "Singapore", "Malaysia", "Thailand"];
  if (validIds.includes(id)) {
    window.open(`/tour/${id}`, "_blank");
  }
};


  return (
    <div className="tours">
      <div className="_header">
        <div className="header_in">
          <span>top cities</span>
          <h1>
            Discover{" "}
            <em>
              {" "}
              your next <br /> travel{" "}
            </em>{" "}
            adventure
          </h1>
          <p>
            Explore exotic destinations, uncover hidden gems, and create
            unforgettable memories along the way.
          </p>
        </div>
      </div>
      <div className="tours_in">
        <span onClick={() => handleClick("Bali")}>
          <div className="tourItem" id={"Bali"}>
            <img
              src={require("../../img/Bali.jpg")}
              alt=""
              className="tourImg"
            />
            <div className="co_filter"></div>
            <div className="tourTitles">
              <h1>Bali</h1>
            </div>
            <div className="bottom">
              <span>Bali beach</span>
              <p>5 Popular places</p>
            </div>
          </div>
        </span>
        <span onClick={() => handleClick("Singapore")}>
          <div className="tourItem">
            <img
              src={require("../../img/Singapore.jpg")}
              alt=""
              className="tourImg"
            />
            <div className="co_filter"></div>
            <div className="tourTitles">
              <h1>Singapore</h1>
            </div>
            <div className="bottom">
              <span>Universal Studios</span>
              <p>5 Popular places</p>
            </div>
          </div>
        </span>
        <span onClick={() => handleClick("Malaysia")}>
          <div className="tourItem">
            <img
              src={require("../../img/Malaysia.jpg")}
              alt=""
              className="tourImg"
            />
            <div className="co_filter"></div>
            <div className="tourTitles">
              <h1>Malaysia</h1>
            </div>
            <div className="bottom">
              <span>Batu Caves</span>
              <p>5 Popular places</p>
            </div>
          </div>
        </span>
        <span onClick={() => handleClick("Thailand")}>
          <div className="tourItem">
            <img
              src={require("../../img/Thailand.jpg")}
              alt=""
              className="tourImg"
            />
            <div className="co_filter"></div>
            <div className="tourTitles">
              <h1>Thailand</h1>
            </div>
            <div className="bottom">
              <span>The Grand Palace</span>
              <p>5 Popular places</p>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default Tours
