import React, { useEffect, useState } from "react";
import "./home.scss";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/header /Header";
import Featured from "../../components/featured/Featured";
import PropertyList from "../../components/propertyList/PropertyList";
import Tours from "../../components/tours/Tours";
import NewLetter from "../../components/newsLetter/NewLetter";
import CityList from "../../components/city/CityList";
import CityCarousel from "../../components/carousel/CityCarousel";
import TopBarProgress from "react-topbar-progress-indicator";

const Home = () => {
  // const {_id} = useParams()
  const [places, setPlaces] = useState([]);

  // useEffect(() => {
  //   const cancelToken = axios.CancelToken.source();
  //   axios
  //     .get("/getAllListing", { cancelToken: cancelToken.token })
  //     .then((response) => {
  //       const inData = response.data;
  //       setPlaces(inData);
  //     })
  //     .catch((error) => {
  //       if (axios.isCancel(error)) {
  //         console.log("Request canceled", error.message);
  //       } else {
  //         // Handle other errors
  //         console.error("Error:", error.message);
  //       }
  //     });

  //   return () => {
  //     cancelToken.cancel();
  //   };
  // }, []);

  //  console.log(places);

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <Header />
      <div className="__mw" style={{ marginBottom: "40px " }}>
        <div className="main_heading">
          <h1>
            Discover{" "}
            <em>
              {" "}
              Affordable and Comfortable Stays <br />
            </em>{" "}
            for Your Next Trip to <em>Sri Lanka</em>
          </h1>

          <p>
            Indulge in Sri Lankan Hospitality Affordable Stays for Unforgettable
            Experiences
          </p>
        </div>

        {/* <div className="offer_banner_two">
          <img src={require("../../img/offer2.avif")} alt="" />
        </div> */}
        <CityCarousel />
        {/* <Featured /> */}
        <div className="offer_banner">
          <img src={require("../../img/offer2.avif")} alt="" />
        </div>
        <Tours />
      </div>
      <NewLetter />
    </div>
  );
};

export default Home;
