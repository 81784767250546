import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";

const ActiveCode = () => {
  const [activation_code, setActivation_code] = useState("");
  const [activation_token, setActivation_token] = useState("");

  const navigate = useNavigate();

  const credentials = {
    activation_code,
    activation_token,
  };

  useEffect(() => {
    const userDataJSON = localStorage.getItem("userData");
    const userData = JSON.parse(userDataJSON);
    setActivation_token(userData.activationToken);
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/active-user", credentials);
      navigate("/login");
    } catch (err) {
      console.log(err);
      navigate("/register");
    }
  };

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="bg-tex">
          <div class="ls-Background-background">
            <div class="ls-ls-Background-background"></div>
            <div
              class="ls-Background-background-s-left"
              style={{ background: "#0073ff" }}
            ></div>
            <div
              class="ls-Background-background-f-s-rihgt"
              style={{ background: "#ffb700" }}
            ></div>
          </div>
        </div>
        <div className="login">
          <div className="_log_in">
            <>
              <div className="_in_h">
                <h2>Active Account</h2>
                <br />
                <p>Enter activation code that has been sent to your email.</p>
              </div>
              <form onSubmit={handleClick}>
                <label>Activation code </label>
                <input
                  type="Number"
                  placeholder="5437"
                  value={activation_code}
                  onChange={(e) => setActivation_code(e.target.value)}
                />
                <br />
                <button className="button">Active</button>
                <div className="_rg_pg">
                  <span>
                    Don't have an account yet?{" "}
                    <Link style={{ color: "#40c0f0" }} to={"/register"}>
                      {" "}
                      Register now
                    </Link>
                  </span>
                </div>
              </form>
            </>
            <div className="cross_mark">
              <div className="_icon">
                <Link to={"/List-your-property"}>
                  <ClearIcon className="icon" fontSize="20" color="black" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveCode;
