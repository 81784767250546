import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import "./hostRegister.scss";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { MdDateRange } from "react-icons/md";

const FlightBooking = () => {
  const [fName, setFName] = useState("");
  const [option, setOption] = useState("")
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [adult, setAdult] = useState("");
  const [phone, setPhone] = useState("");
  const [children, setChildren] = useState("");
  const [sriLanka, setSriLanka] = useState("Sri Lanka");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [openDate, setOpenDate] = useState(false);

  // ! buuton
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const x = e.pageX - e.target.offsetLeft;
    const y = e.pageY - e.target.offsetTop;
    setCoordinates({ x, y });
  };

  const navigate = useNavigate();

  const ragisterUser = async (ev) => {
    ev.preventDefault();
    try {
      await axios.post("/flightInq", {
        fName,
        lName,
        email,
        country,
        adult,
        phone,
        children,
        sriLanka,
        returnDate,
        departureDate,
        option,
      });
      navigate("/");
    } catch (e) {
      console.log("Message:", "User registration failed. Please try again.");
    }
  };

  // ! select Listing
  const handleListingChange = (event) => {
    setOption(event.target.value);
  };

  return (
    <div>
      <div className="h_login">
        <div className="h_log_in">
          <>
            <div className="h_in_h">
              <span>Search and booking process effortlessly with us</span>
              {/* <span>Continue your registration</span> */}
            </div>
            <form onSubmit={ragisterUser}>
              <div className="input_form">
                <div className="input_wrp">
                  <label>First Name</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="John "
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                  />
                </div>
                <div className="input_wrp">
                  <label>Last Name</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Doe"
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input_wrp">
                <label>E mail</label>
                <input
                  className="input"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <label>Phone</label>
                <input
                  className="input"
                  type="Number"
                  placeholder="+940123456789"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input_form">
                <div className="input_wrp">
                  <label>Adult Age 18+</label>
                  <input
                    className="input"
                    type="Number"
                    placeholder="1"
                    value={adult}
                    onChange={(e) => setAdult(e.target.value)}
                  />
                </div>
                <div className="input_wrp">
                  <label>Children Age 0 – 17</label>
                  <input
                    className="input"
                    type="Number"
                    placeholder="1"
                    value={children}
                    onChange={(e) => setChildren(e.target.value)}
                  />
                </div>
              </div>
              <div className="input_form">
                <div className="input_wrp">
                  <label>From</label>
                  <input
                    className="input"
                    type="Text"
                    placeholder="Write your place"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>

                <div className="input_wrp">
                  <label>Where to ?</label>
                  <input
                    className="input"
                    type="Text"
                    placeholder="Sri lanka"
                    value={sriLanka}
                    onChange={(e) => setSriLanka(e.target.value)}
                  />
                </div>
              </div>
              <div className="radio_btn_reg">
                <div className="radio_in">
                  <div className="radio_in_head">
                    <label>Round Trip</label>
                  </div>
                  <input
                    type="Radio"
                    value={"round"}
                    checked={option === "round"}
                    onChange={handleListingChange}
                  />
                </div>
                <div className="radio_in">
                  <div className="radio_in_head">
                    <label>One way</label>
                  </div>
                  <input
                    type="Radio"
                    value={"oneway"}
                    checked={option === "oneway"}
                    onChange={handleListingChange}
                  />
                </div>
              </div>
              <div className="input_form">
                <div className="input_wrp">
                  <label>Departure</label>
                  <input
                    type="date"
                    value={departureDate}
                    onChange={(e) => setDepartureDate(e.target.value)}
                  />
                </div>
                <div className="input_wrp">
                  <label>Return</label>
                  <input
                    type="date"
                    value={returnDate}
                    onChange={(e) => setReturnDate(e.target.value)}
                  />
                </div>
              </div>
              <button
                className="button"
                onMouseMove={handleMouseMove}
                style={{
                  "--x": `${coordinates.x}px`,
                  "--y": `${coordinates.y}px`,
                }}
              >
                Inquirer Now
              </button>
            </form>
          </>
        </div>
      </div>
    </div>
  );
};

export default FlightBooking;
