import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import "./hostRegister.scss";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { MdDateRange } from "react-icons/md";


const AirPortTexi = () => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState("");
  const [passengers, setPassengers] = useState("");
  const [phone, setPhone] = useState("");
  const [where, setWhere] = useState("");
  const [dates, setDates] = useState();
  const [openDate, setOpenDate] = useState(false);

   

  // ! buuton
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const x = e.pageX - e.target.offsetLeft;
    const y = e.pageY - e.target.offsetTop;
    setCoordinates({ x, y });
  };

  const navigate = useNavigate();

  const ragisterUser = async (ev) => {
    ev.preventDefault();
    try {
      await axios.post("/airPortTaxiInq", {
        fName,
        lName,
        email,
        from,
        passengers,
        phone,
        where,
        dates,
      });
      navigate("/");
    } catch (e) {
      console.log("Message:", "User registration failed. Please try again.");
    }
  };

  

  return (
    <div>
      <div className="h_login">
        <div className="h_log_in">
          <>
            <div className="h_in_h">
              <span>Book your airport taxi</span>
              {/* <span>Continue your registration</span> */}
            </div>
            <form onSubmit={ragisterUser}>
              <div className="input_form">
                <div input_wrp>
                  <label>First Name</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="John "
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                  />
                </div>
                <div className="input_wrp">
                  <label>Last Name</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Doe"
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input_wrp">
                <label>E mail</label>
                <input
                  className="input"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <label>Phone</label>
                <input
                  className="input"
                  type="Number"
                  placeholder="+940123456789"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input_wrp">
                <label>Passengers</label>
                <input
                  className="input"
                  type="Number"
                  placeholder="1"
                  value={passengers}
                  onChange={(e) => setPassengers(e.target.value)}
                  min="1"
                />
              </div>
              <div className="input_form">
                <div className="input_wrp">
                  <label>From</label>
                  <input
                    className="input"
                    type="Text"
                    placeholder="From where"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                  />
                </div>

                <div className="input_wrp">
                  <label>Where to ?</label>
                  <input
                    className="input"
                    type="Text"
                    placeholder="Where you need to go"
                    value={where}
                    onChange={(e) => setWhere(e.target.value)}
                  />
                </div>
              </div>

              <div className="input_wrp">
                <label>Date and time</label>
                <input
                  className="input"
                  type="datetime-local"
                  value={dates}
                  onChange={(e) => setDates(e.target.value)}
                ></input>
              </div>

              <button
                className="button"
                onMouseMove={handleMouseMove}
                style={{
                  "--x": `${coordinates.x}px`,
                  "--y": `${coordinates.y}px`,
                }}
              >
                Inquirer Now
              </button>
            </form>
          </>
        </div>
      </div>
    </div>
  );
}

export default AirPortTexi
