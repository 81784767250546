const countries = [
  "Mount Lavinia",
  "Piliyandala",
  "Borella",
  "Padukka",
  "Watareka",
  "Dehiwala",
  "Ratmalana",
  "Kolonnawa",
  "Kotte",
  "Kaduwela",
  "Awissawella",
  "Homagama",
  "Maharagama",
  "Kesbewa",
  "Moratuwa",
  "Waga",
  "Hanwella",
  "Battaramulla",
  "Colombo 1",
  "Colombo 2",
  "Colombo 3",
  "Colombo 4",
  "Colombo 5",
  "Colombo 6",
  "Colombo 7",
  "Colombo 8",
  "Colombo 9",
  "Colombo 10",
  "Colombo 11",
  "Colombo 12",
  "Colombo 13",
  "Colombo 14",
  "Colombo 15",
  "Wattala",
  "Agalawatta",
  "Panadura",
  "Bandaragama",
  "Horana",
  "Bulathsinhala",
  "Mathugama",
  "Wadduwa",
  "Beruwala",
  "Panadura",
  "Ingiriya",
  "Galagedara",
  "Hunnasgiriya",
  "Knuckles",
  "Hunnasgiriya",
  "Theldeniya",
  "Kundasale",
  "Gampola",
  "Nawalapitiya",
  "Digana",
  "Peradeniya",
  "Meemure",
  "Dambulla",
  "Laggala",
  "Matale",
  "Raththota",
  "Sigiriya",
  "Dambulla",
  "Riverston",
  "Wasgamuwa",
  "Kithulgala",
  "Kotmale",
  "Ambewela",
  "Ohiya",
  "Hatton",
  "Maskeliya",
  "Balapitiya",
  "Ambalangoda",
  "Karandeniya",
  "Elpitiya",
  "Hiniduma",
  "Baddegama",
  "Rathgama",
  "Akmeemana",
  "Habaraduwa",
  "Bentota",
  "Kosgoda",
  "Ahungalla",
  "Hikkaduwa",
  "Koggala",
  "Ahangama",
  "Talpe",
  "Unawatuna",
  "Neluwa",
  "Deniyaya",
  "Hakmana",
  "Akuressa",
  "Kamburupitiya",
  "Devinuwara",
  "Polhena",
  "Weligama",
  "Mirissa",
  "Dikwella",
  "Urubokka",
  "Kotapola",
  "Morawaka",
  "Sinharaja",
  "Mulkirigala",
  "Beliatta",
  "Tangalle",
  "Thissamaharamaya",
  "Walasmulla",
  "Ambalantota",
  "Ranna",
  "Rekawa",
  "Middeniya",
  "Ridiyagama",
  "Sooriyawewa",
  "Mattala",
  "Weerawila",
  "Situlpawwa",
  "Yala",
  "Kirinda",
  "Kayts",
  "Waddukkoddai",
  "Kankasanthurai",
  "Manipay",
  "Kopay",
  "Uduppidi",
  "Point Pedro",
  "Chawakachcheri",
  "Nallur",
  "Jaffna",
  "Delft",
  "Vavuniya",
  "Silavathurai",
  "Madu",
  "Murunkan",
  "Thiruketheeswaram",
  "Vakari",
  "Kallady",
  "Paddiruppu",
  "Kattankudy",
  "Kalkudah",
  "Sallitheevu",
  "Samanthurai",
  "Kalmunai",
  "Pothuvil",
  "Dehiattakandiya",
  "Maduru Oya ",
  "Maha Oya",
  "Gal Oya",
  "Uhana",
  "Lahugala",
  "Oluvil",
  "Akkaraipattu",
  "Pottuvil",
  "Arugam Bay",
  "Panama",
  "Seruwil",
  "Mutur",
  "Pulmoddai",
  "Kumpurupiddi",
  "Nilaveli",
  "Kuchchaveli",
  "Kantale",
  "Seruwawila",
  "Somawathiya",
  "Galgamuwa",
  "Nikaweratiya",
  "Yapahuwa",
  "Hiriyala",
  "Wariyapola",
  "Panduwasnuwara",
  "Bingiriya",
  "Katugampola",
  "Kuliyapitiya",
  "Dambadeniya",
  "Polgahawela",
  "Kurunegala",
  "Mawathagama",
  "Dodangaslanda",
  "Melsiripura",
  "Ibbagamuwa",
  "Wilpattu",
  "Horowpathana",
  "Tambuttegama",
  "Medawachchiya",
  "Avukana",
  "Siyambalangamuwa",
  "Habarana",
  "Hiriwadunna",
  "Mahawilachchiya",
  "Thanthirimale",
  "Minneriya",
  "Medirigiriya",
  "Kaudulla",
  "Girithale",
  "Dimbulagala",
  "Mahiyanganaya",
  "Passara",
  "Hali Ela",
  "Uva Paranagama",
  "Bandarawela",
  "Haputale",
  "Bandarawela",
  "Diyatalawa",
  "Boralanda",
  "Welimada",
  "Hakgala",
  "Pattipola",
  "Beragala",
  "Ella",
  "Bibile",
  "Bibila",
  "Kataragama",
  "Wellawaya",
  "Udawalawe",
  "Buttala",
  "Sewanagala",
  "Gal Oya",
  "Lunugamvehera",
  "Siyambalanduwa",
  "Eheliyagoda",
  "Pelmadulla",
  "Balangoda",
  "Rakwana",
  "Nivithigala",
  "Kalawana",
  "Kolonna",
  "Balangoda",
  "Belihuloya",
  "Kuruwita",
  "Suriyakanda",
  "Embilipitiya",
  "Sinharaja",
  "Dedigama",
  "Galigamuwa",
  "Rambukkana",
  "Mawanella",
  "Aranayaka",
  "Yatiyanthota",
  "Ruwanwella",
  "Deraniyagala",
  "Kalpitiya",
  "Chilaw",
  "Marawila",
  "Wennappuwa",
  "Madampe",
  "Anamaduwa",
  "Wilpattu",
  "Thabbowa",
  "Pooneryn",
  "Veravil",
  "Kumulamunai",
  "Chunnavil",
  "Iranamadu",
  "Vaddakkachchi",
  "Parantan",
  "Thadduvankoddi",
  "Mankulam",
  "Alankulam",
  "Visuvamadu",
  "Welioya",
  "Chemmalai",
  "Mullaitivu",
  "Kilinochchi",
  "Puttalam",
  "Kegalle",
  "Ratnapura",
  "Moneragala",
  "Badulla",
  "Polonnaruwa",
  "Anuradhapura",
  "Kurunegala",
  "Trincomalee",
  "Ampara",
  "Batticaloa",
  "Mannar",
  "Jaffna",
  "Hambantota",
  "Matara",
  "Galle",
  "Nuwara-Eliya",
  "Matale",
  "Kandy",
  "Kalutara",
  "Gampaha",
  "Colombo",
];
export default countries;