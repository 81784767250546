import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./cardSkeliton.scss";



const CardSkeleton = () => {
const items = Array(8).fill({});

  return (
    <div className="sk_wrp">
      <div className="man_card">
        {items.map((i)=>(
        <div className="place_card " key={i}>
          <div className="sk_img">
            <Skeleton width={271.25} height={271.25} />
          </div>
          <div>
            <Skeleton width={200} count={2} />
          </div>
          <div className="_prce">
            <Skeleton width={100} />
          </div>
        </div>
        ))}
      </div>
    </div>
  );
};

export default CardSkeleton;
