import React from 'react'
import background from "../../img/leisurey_.jpg";
import FlightBooking from '../../components/hostRegister/FlightBooking';

const Flight = () => {
  return (
    <div
      className="__fw"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight:"80dvh",
        height:"auto",
        alignItems:"flex-start"
      }}
    >
      <div className="__mw">
        <div className="list">
          <div className="list-item">
            <h1>
              Experience the best in-flight journey to <em>Sri Lanka</em> with
              our expert assistance
            </h1>
            <p>
              Let us elevate your travel with seamless bookings and personalized
              service. Your journey begins with us.
            </p>
          </div>
          <div className="list-item">
            <FlightBooking />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Flight
