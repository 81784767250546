import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthContext } from "../../../context/AuthContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "../../../pages/singalPlace/singalPlace.scss";
import { differenceInCalendarDays, format } from "date-fns";
import "./bookingPage.scss";
import useFetch from "../../../hooks/useFetch";
import { FaCircleUser } from "react-icons/fa6";

const appStyles = {
  height: 10,
  width: 400,
  margin: 10,
};

const BookingPage = () => {
  const { listingId } = useParams();
  const { user } = useContext(AuthContext);
  const { userId, bookingId, placeId } = useParams();
  const [guestBooking, setguestBooking] = useState([]);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [selectRating, setSelectRating] = useState("1");
  const [comment, setComment] = useState("");
  const [saveComment, setSaveComment] = useState("");
  const [progress, setProgress] = useState(0);

  const [thanksMsg, setThanksMsg] = useState("");
  const [selectOption, setSelectOption] = useState("other");
  const [message, setMessage] = useState("");

  const { data, loading, error, refetch } = useFetch(`/singalPlace/${placeId}`);

  const guestCommnet = data.guestReview;

  // const messageList = guestBooking.flatMap((item)=>item.messages)

  const messageList = guestBooking.messages;
  const messageBodyRef = useRef(null);

  // ! select rating
  const handleListingChange = (event) => {
    setSelectRating(event.target.value);
  };

  // ! select Option
  const handleOption = (event) => {
    setSelectOption(event.target.value);
  };

  const checkInDate = guestBooking?.checkIn
    ? new Date(guestBooking.checkIn)
    : null;
  const checkOutDate = guestBooking?.checkOut
    ? new Date(guestBooking.checkOut)
    : null;
  //! scrool top
  useEffect(() => {
    if (messageBodyRef.current) {
      messageBodyRef.current.scrollTop = messageBodyRef.current.scrollHeight;
    }
  }, [messageList]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        await axios
          .get(`/guestSingalBooking/${userId}/${bookingId}`, {
            cancelToken: cancelToken.token,
          })
          .then((response) => {
            setguestBooking(response.data);
          });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          // Handle other errors
          console.error("Error:", error.message);
        }
      }
    };

    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [userId, bookingId, saveComment, message]);

  const dateDef = () => {
    const daysDifference = differenceInCalendarDays(checkOutDate, checkInDate);
    return daysDifference;
  };
  const daysDifference = dateDef();
  // console.log(daysDifference);

  if (showAllPhotos) {
    return (
      <div className="show_all_pic">
        <div className="show_in">
          <h2>Photo of - {data.title}</h2>
          <div className="close_btn">
            <span onClick={() => setShowAllPhotos(false)}>
              <ArrowBackIosIcon />
            </span>
          </div>

          {data.photos?.length > 0 &&
            data.photos.map((item) => (
              <div key={item.id}>
                <img src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${item}`} alt="" />
              </div>
            ))}
        </div>
      </div>
    );
  }

  const saveCommnet = async (e) => {
    e.preventDefault();
    const list = {
      selectRating,
      comment,
      owner: data.owner,
      listingId: data.listingId,
      guestName: data.fName,
      guestCountry: user.country,
      guestId: user.id,
    };

    try {
      await axios.post("/saveReview", list).then((response) => {
        setSaveComment(response.data);
        refetch();
      });
      setThanksMsg("Thanks for your feedback");
    } catch (error) {
      console.log(error);
    }
    setComment("");
    setSelectRating("");
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const list = {
      selectOption,
      userId,
      message,
      guestName: user.fName + " " + user.lName,
    };
    try {
      await axios.post(`sendMessage/${userId}/${bookingId}`, list);
    } catch (error) {
      console.log(error);
    }
    setMessage("");
  };

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="singal_place">
          <div className="booking_head">
            <h2>Booking summary</h2>
          </div>
          <div className="booking_info">
            <div className="booking_title">
              <div
                className="_bookig_card"
                style={{ backgroundColor: "white" }}
              >
                <div>
                  {/* <div className="img">
                    <div className="img_in">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${guestBooking.placeImg}`}
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="info">
                    <div className="info_in">
                      <strong>{guestBooking.name}</strong>
                      <span>{data.email}</span>
                      <em>{guestBooking.country}</em>
                      <p>{guestBooking.placeName}</p>
                    </div>
                  </div>
                  <div className="data">
                    <div className="check">
                      <span>Check-In</span>
                      <small>
                        {checkInDate
                          ? format(checkInDate, "MM/dd/yyyy")
                          : "N/A"}
                      </small>
                    </div>
                    <div className="pograss">
                      <div className="days">
                        <span>{daysDifference} - Nights</span>
                      </div>
                      <div className="pograss_bar" style={appStyles}>
                        <div bgColor={"var(--blue)"} progress={progress} />
                      </div>
                    </div>
                    <div className="check">
                      <span>Check-Out</span>
                      <small>
                        {checkOutDate
                          ? format(checkOutDate, "MM/dd/yyyy")
                          : "N/A"}
                      </small>
                    </div>
                  </div>
                  <div className="price">
                    <div className="price_in">
                      <em>Adults</em>
                      <div className="strong">
                        <strong>{guestBooking.adults}</strong>
                      </div>
                    </div>
                    <div className="price_in">
                      <em>Children</em>
                      <div className="strong">
                        <strong>{guestBooking.children}</strong>
                      </div>
                    </div>
                    <div className="price_in">
                      <em>Infants</em>
                      <div className="strong">
                        <strong>{guestBooking.infants}</strong>
                      </div>
                    </div>
                    <div className="price_in">
                      <em>Rs </em>
                      <div className="strong">
                        <h3> {guestBooking.price?.toFixed(2)} </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_messageBody">
                  <div className="booking_form">
                    <div className="message_body" ref={messageBodyRef}>
                      <div className="messageBody">
                        <h2>❤️</h2>
                        <span>
                          Thank you for booking with us! This guide will have
                          everything you need to know about your stay with
                          us.Feel free to refer to this whenever you need to, or
                          message us for any clarifications.Welcome to {},hope
                          you enjoy your stay!
                        </span>
                      </div>
                      {messageList?.map((item, index) => (
                        <div key={index} className="masg_wrap">
                          <div className="msg_icon">
                            <FaCircleUser />
                            <strong>{item.name}</strong>
                          </div>
                          <div className="em">
                            <em>Subject: {item.selectOption}</em>
                          </div>
                          <small>{item.message}</small> <br />
                          <span>
                            {new Date(item.createdTime).toLocaleString()}
                          </span>
                        </div>
                      ))}
                    </div>

                    <form onSubmit={sendMessage}>
                      <div className="booking_form_input">
                        <div className="booking_select_wrap">
                          <div className="booking_select">
                            <input
                              type="radio"
                              value={"earlyCheckIn"}
                              checked={selectOption === "earlyCheckIn"}
                              onChange={handleOption}
                            />
                            <small>Early check in</small>
                          </div>

                          <div className="booking_select">
                            <input
                              type="radio"
                              value={"cancel"}
                              checked={selectOption === "cancel"}
                              onChange={handleOption}
                            />
                            <small>Cancel</small>
                          </div>

                          <div className="booking_select">
                            <input
                              type="radio"
                              value={"other"}
                              checked={selectOption === "other"}
                              onChange={handleOption}
                            />
                            <small>Other</small>
                          </div>
                        </div>
                        <textarea
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <div className="send_btn">
                        <button className="__btn_">Send</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="giveComment">
            <div className="giveComment_in">
              <div className="head_info">
                <h3>Your Opinion Matters! </h3>
                <p>welcome all feedback.</p>
              </div>
              <form onSubmit={saveCommnet}>
                <div className="input">
                  <label>Write feedback</label>
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
                <div className="_rating">
                  <div className="lable">
                    <label>Add your rating</label>
                  </div>

                  <ul>
                    <li>
                      <label>
                        <h1>😐</h1>
                      </label>
                      <input
                        type="radio"
                        value={"1"}
                        name="1"
                        checked={selectRating === "1"}
                        onChange={handleListingChange}
                      />
                      <small>1</small>
                    </li>
                    <li>
                      <label>
                        <h1>🙂</h1>
                      </label>
                      <input
                        type="radio"
                        value={"2"}
                        name="2"
                        checked={selectRating === "2"}
                        onChange={handleListingChange}
                      />
                      <small>2</small>
                    </li>
                    <li>
                      <label>
                        <h1>😊</h1>
                      </label>
                      <input
                        type="radio"
                        value={"3"}
                        name="3"
                        checked={selectRating === "3"}
                        onChange={handleListingChange}
                      />
                      <small>3</small>
                    </li>
                    <li>
                      <label>
                        <h1>😇</h1>
                      </label>
                      <input
                        type="radio"
                        value={"4"}
                        name="4"
                        checked={selectRating === "4"}
                        onChange={handleListingChange}
                      />
                      <small>4</small>
                    </li>
                    <li>
                      <label>
                        <h1>😍</h1>
                      </label>
                      <input
                        type="radio"
                        name="5"
                        value={5}
                        checked={selectRating === "5"}
                        onChange={handleListingChange}
                      />
                      <small>5</small>
                    </li>
                  </ul>
                </div>

                <div className="review_btn">
                  <button className="__btn">submit review</button>
                </div>
                <span>{thanksMsg}</span>
              </form>
            </div>
          </div>
          <hr />
          <div className="review_wrap">
            <div className="review_wrap_in">
              {guestCommnet?.length > 0 &&
                guestCommnet?.map((item, index) => (
                  <div className="review_card" key={index}>
                    <div className="review_card_head">
                      <div className="review_card_img">
                        <img
                          src={require("../../../img/profile.jpeg")}
                          alt=""
                        />
                      </div>
                      <div className="review_card_info">
                        <h3>{item.guestName}</h3>
                        <span>{item.guestCountry}</span>
                      </div>
                    </div>
                    <div className="rating_wrap">
                      <div className="rating_wrap_rating">
                        {Array.from({ length: 5 }, (_, i) => (
                          <p key={i + 1}>
                            {i + 1 <= item.selectRating ? "★" : ""}
                          </p>
                        ))}
                      </div>
                      <div className="_time">
                        <span>1 week ago</span>
                      </div>
                      <div className="_stay">
                        <span>Stayed a few nights</span>
                      </div>
                    </div>
                    <div className="guest_comment">
                      <p>{item.comment}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
