import React, { useContext, useEffect } from "react";
import "./successPay.scss";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

const SuccessPay = () => {
  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw" style={{ marginTop: "60px" }}>
        <div className="addNew_in">
          <div className="payment-success">
            <div className="bosccess_wrp">
              <div className="bosccess_wrp_hed">
                <h2 className="title">Payment Successful</h2>
              </div>
              <img
                className="main-img"
                src="https://res.cloudinary.com/dw1zug8d6/image/upload/v1542777688/group-3_3x.png"
                alt=""
              />
              <p>
                Your payment was successful! You can <br />
                now continue using Artycoin.
              </p>
              <div className="pay_wrp">
                <Link to={"/user-profile"} className="btn btn-primary">
                  See Your Booking
                </Link>
              </div>

              <Link to={"/"} className="cancel">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default  SuccessPay;