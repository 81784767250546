import React, { useContext, useState } from "react";
import "./LoginAccount.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";

import image from "../../img/banner.jpeg";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

const initialState = {
  email: "",
  password: "",
};

const LoginAccout = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { user, loading, dispatch } = useContext(AuthContext);
  // console.log(error);

  const navigate = useNavigate();

  const credentials = {
    email,
    password,
  };

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
      if (res.data.details.role === "host") {
        // Use res.data.details for role
        navigate("/hosting");
      } else if (res.data.details.role === "guest") {
        // Use res.data.details for role
        navigate("/");
      } else {
        return;
      }
    } catch (err) {
      dispatch({
        type: "LOGIN_FAILURE",
        payload:
          err.response && err.response.data
            ? err.response.data
            : "Unknown error",
      });
      setError("Invalid email or password");
    }
  };

  return (
    <>
      <form onSubmit={handleClick}>
        <div className="input_wrp">
          <label>E mail</label>
          <input
            autocomplete="off"
            type="email"
            placeholder="your@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input_wrp">
          <label>Password</label>
          <input
            autocomplete="off"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <small style={{ color: "red" }}>{error}</small>
        <br />
        <button className="button">Sign in</button>
      </form>
    </>
  );
};

export default LoginAccout;
