import React, { useEffect, useState } from "react";
import "./textSlide.scss";

const TextSlide = () => {
  const sentences = [
    "Book your escape, delight awaits",
    "Explore, unwind, and reserve tranquility",
    "Dream destinations, snug stays, reserve now",
    "Adventures beckon, nights await your booking",
    "Experience wanderlust, cozy comforts guaranteed.",
  ];

  const [index, setIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

 useEffect(() => {
   const timer = setInterval(() => {
     setIsVisible(false);
     setTimeout(() => {
       const nextIndex = (index + 1) % sentences.length;
       setIndex(nextIndex);
       setIsVisible(true);
     }, 1000); // Adjust this delay according to your preference
   }, 6000); // Adjust this interval according to your preference

   // Cleanup function to clear the interval
   return () => clearInterval(timer);
 }, [index]);

  return (
    <div className="container">
      <div className="text-slide-container">
        <div className={isVisible ? "fade-in-up" : "fade-out-up"}>
          {sentences[index]}
        </div>
      </div>
    </div>
  );
};

export default TextSlide;
