import React from 'react'

const Error = () => {
  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="error">
          <h1>404</h1>
        </div>
      </div>
    </div>
  );
}

export default Error
