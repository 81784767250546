import React, { useContext, useEffect, useState } from "react";
import "./list.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useFetch from "../../hooks/useFetch";
import { format } from "date-fns";
import { DateRange, DateRangePicker } from "react-date-range";
import { MdDateRange } from "react-icons/md";
import Header from "../header /Header";
import ListHeader from "../listheader/ListHeader";
import { IoMdHeartEmpty } from "react-icons/io";
import LoadingPro from "../loading/LoadingPro";
import CardSkeleton from "../cardSkeleton/CardSkeleton";
import { BsFire } from "react-icons/bs";
import { GoStarFill } from "react-icons/go";

const List = () => {
  const location = useLocation();

  const initialState = {
    destination: "",
    dates: [{ startDate: new Date(), endDate: new Date(), key: "selection" }],
    options: { adult: 1, children: 0, infants: 0 },
  };

  const state = location.state || initialState;

  const [checkIn, setCheckIn] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [checkOut, setCheckOut] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [destination, setDestination] = useState(state.destination);
  const [options, setOptions] = useState(state.options);
  const [dates, setDates] = useState(state.dates);

  const [children, setChildren] = useState(0);
  const [adults, setAdults] = useState(1);
  const [infants, setInfants] = useState(0);
  const [place, setPlace] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [visible, setVisible] = useState(12);
  const [placeType, setPlaceType] = useState("");

  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setDates([selection]); // Auto close the date picker
  };

  const handleRangeFocusChange = (ranges) => {
    if (ranges[1] === 0) {
      // Focus has shifted to the end date, indicating selection of the end date trending
      setOpenDate(false);
    }
  };

  const { data, loading, error, reFetch } = useFetch(
    `/listings?city=${destination}&min=${min || 0}&max=${
      max || 9999
    }&placeType=${placeType}&startDate=${dates[0].startDate.toISOString()}&endDate=${dates[0].endDate.toISOString()}`
  );

   

  const handleClick = (e) => {
    e.preventDefault();
    reFetch();
  };

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 12);
  };

  const generateBookingLink = (listingId) => {
    const queryParams = new URLSearchParams({
      numberOfAdults: options.adult,
      numberOfChildren: options.children,
      numberOfInfants: options.infants,
      checkIn: dates.map((item) => item.startDate),
      checkOut: dates.map((item) => item.endDate),
    }).toString();
    return `/place/${listingId}/?${queryParams}`;
  };

  return (
    <div className="listWrap">
      <div className="__fw stiky">
        <div className="__mw">
          <div className="__place_info_b">
            <form onSubmit={handleClick}>
              <div className="_place_date_main">
                <div className="___date">
                  <div className="_city_list">
                    <input
                      className="noBorder"
                      type="text"
                      placeholder={destination || "Enter destination"}
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                    />
                  </div>
                  <div className="_city_list_date">
                    <div
                      className="_headerSearchItem_list noBorder"
                      onClick={() => setOpenDate(!openDate)}
                    >
                      <span className="headerSerachText_list">
                        <em>CheckIn</em>{" "}
                        {`${format(dates[0].startDate, "dd/MM/yyyy")}`}
                        <em> - </em>
                        <em>CheckOut</em>{" "}
                        {`${format(dates[0].endDate, "dd/MM/yyyy")}`}
                      </span>
                      {openDate && (
                        <div
                          className="datePicker"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DateRange
                            editableDateInputs={true}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={dates}
                            className="date"
                            minDate={new Date()}
                            onRangeFocusChange={handleRangeFocusChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="_city_list">
                    <div className="_city_list_in">
                      <select
                        className="noBorder"
                        value={placeType}
                        onChange={(e) => setPlaceType(e.target.value)}
                      >
                        <option value="">Property type</option>
                        <option value="room">Room</option>
                        <option value="home">Home</option>
                        <option value="villa">Villa</option>
                        <option value="resort">Resort</option>
                        <option value="nature lodge">Nature lodge</option>
                        <option value="bungalow">Bungalow</option>
                        <option value="boutique hotel">Boutique Hotel</option>
                        <option value="apartment">Apartment</option>
                        <option value="cabin">Cabin</option>
                        <option value="treehoues">Treehoues</option>
                        <option value="place to stay">Place to Stay</option>
                        <option value="hut">Hut</option>
                        <option value="cottage">Cottage</option>
                        <option value="bed and breakfast">
                          Bed and Breakfast
                        </option>
                        <option value="townhoues">Townhoues</option>
                        <option value="vacation home">Vacation home</option>
                        <option value="guesthoues">Guesthoues</option>
                        <option value="hotel">Hotel</option>
                      </select>
                    </div>
                  </div>

                  <div className="_city_list_max">
                    <div className="_city_list">
                      <input
                        className="noBorder"
                        type="number"
                        value={min}
                        minLength={0}
                        placeholder="Min "
                        onChange={(e) => setMin(e.target.value)}
                      />
                    </div>

                    <div className="_city_list">
                      <input
                        className="noBorder"
                        type="number"
                        value={max}
                        minLength={0}
                        placeholder="Max "
                        onChange={(e) => setMax(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="_city_list">
                    <button className="_btn_list">Search Now</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="__fw hight">
        <div className="__mw">
          <div className="list_right">
            {loading ? (
              <CardSkeleton />
            ) : (
              <div className="_list_right">
                {data && data.length > 0 ? (
                  data.slice(0, visible).map((item) => (
                    <Link
                      to={generateBookingLink(item.listingId)}
                      target="_blank"
                      key={item.listingId}
                    >
                      <div className="place_card list_card">
                        <div className="place_img">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${item.photos}`}
                            alt=""
                          />
                          <Link
                            to={`/favorite/${item.listingId}`}
                            target="_blank"
                          >
                            <div className="favorite">
                              <div className="favorite_in">
                                <span>
                                  <IoMdHeartEmpty />
                                </span>
                              </div>
                            </div>
                          </Link>
                          {item.type === "trending" && (
                            <div className="trending">
                              <div className="trending_in">
                                <span>
                                  <BsFire />
                                </span>
                              </div>
                            </div>
                          )}
                          {/* {item.type === "featured" && (
                            <div className="featured">
                              <div className="featured_in">
                                <span>
                                  <GoStarFill />
                                </span>
                              </div>
                            </div>
                          )} */}
                        </div>

                        <div className="place_info">
                          <div className="place_one">
                            <h2>
                              {item.selectHomeCategory} in {item.city}
                            </h2>
                          </div>
                          <div className="place_two">
                            <h3>{item.title}</h3>
                          </div>
                          <div className="place_four">
                            <span>
                              Rs. {item.salePrice}{" "}
                              <em
                                style={{
                                  fontSize: "10px",
                                  textTransform: "lowercase",
                                }}
                              >
                                avg per night
                              </em>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <CardSkeleton />
                )}
              </div>
            )}
          </div>
          {data.length > 12 && visible < data.length && (
            <div className="show_more">
              <h3>Continue exploring more</h3>
              <div className="see_more" onClick={showMoreItems}>
                <span>Show more</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
