import React, { startTransition, useEffect, useState } from "react";
import "./hostProfile.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppsIcon from "@mui/icons-material/Apps";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";
import useFetch from "../../../hooks/useFetch";

const HostProfile = () => {
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const { listingId, hostId } = useParams();
  const [place, setPlace] = useState(null); // initialize as null to match the error context
  const [places, setPlaces] = useState([]);
  const [isLoadingPlace, setIsLoadingPlace] = useState(true);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState(true);
  const [profile, setProfile] = useState([]);

  const { data, error, refetch } = useFetch(`/singalPlace/${listingId}`);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchAllListings = async () => {
      try {
        const response = await axios.get(`/getHostData/${hostId}`, {
          cancelToken: cancelToken.token,
        });
        setPlaces(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setIsLoadingPlaces(false);
      }
    };

    fetchAllListings();

    return () => {
      cancelToken.cancel();
    };
  }, [hostId]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchPlace = async () => {
      try {
        const response = await axios.get(`/singalPlace/${listingId}`, {
          cancelToken: cancelToken.token,
        });
        setPlace(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setIsLoadingPlace(false);
      }
    };

    if (listingId) {
      fetchPlace();
    }

    return () => {
      cancelToken.cancel();
    };
  }, [listingId]);

  useEffect(() => {
    const ownerId = data?.owner;
    if (ownerId) {
      axios
        .get(`/profile/${ownerId}`)
        .then((response) => {
          startTransition(() => {
            setProfile(response.data);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [data]);

  if (isLoadingPlace || isLoadingPlaces) {
    return <div>Loading...</div>; // Loading state
  }

  if (showAllPhotos) {
    return (
      <div className="show_all_pic">
        <div className="show_in">
          <h2>Photo of - {place?.title}</h2>
          <div className="close_btn">
            <span onClick={() => setShowAllPhotos(false)}>
              <ArrowBackIosIcon />
            </span>
          </div>
          {place?.photos?.length > 0 &&
            place.photos.map((addedPhotos, index) => (
              <div key={index}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${addedPhotos}`}
                  alt=""
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  const backGroundImage = () => {
    if (place?.photos && place.photos.length > 0) {
      return `url(${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${place.photos[0]})`;
    }
    return null; // or any default image URL if there's no photo available
  };
  const backgroundImageStyle = backGroundImage();

  return (
    <>
      <div className="___fw" style={{ minHeight: "60dvh" }}>
        <div
          className="__m_mw"
          style={{
            backgroundImage: backgroundImageStyle,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "contain",
          }}
        >
          <div className="hostProfile">
            <div className="_host_info">
              <Link to={"/"}>
                <div className="home_icon">
                  <HomeIcon color="white" />
                </div>
              </Link>
            </div>
            <div className="host_info">
              <h1>{profile.displayName}</h1>

              <h2>{profile.headTwo}</h2>
              <p>{profile.headOne}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="__fw">
        <div className="__m_mw">
          <div className="place_sections">
            <div className="place_section_head">
              {" "}
              <h2>{profile.displayName}’s listings</h2>{" "}
            </div>
            <div className="place_section_in">
              {places.length > 0 &&
                places.map((item, i) => (
                  <Link
                    to={`/listings`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={i}
                  >
                    <div className="place_card">
                      <div className="place_img">
                        {item.photos?.length > 0 && (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${item.photos[0]}`}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="place_info">
                        <div className="place_one">
                          <h2>{item.title}</h2>
                          <small>{item.listingId}</small>
                        </div>
                        <div className="place_two">
                          <span>{item.city}</span>
                        </div>
                        <div className="place_four">
                          <span>
                            ${item.price} <em>night</em>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="___fw">
        <div className="__m_mw">
          <div className="tag_ling">
            <div className="tag_ling_in">
              <h2>{profile.headThree}</h2>
            </div>
            <hr className="hr-short"></hr>
          </div>
        </div>
      </div>

      <div className="___fw">
        <div className="__m_mw">
          <div className="image_gallry">
            <div className="image_grid_main">
              <div className="grid_one">
                {place?.photos?.[0] && (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${place.photos[0]}`}
                    alt=""
                  />
                )}
              </div>
              <div className=" grid_two">
                <div className="grid_two_main">
                  <div className="grid_two_in">
                    {place?.photos?.[1] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${place.photos[1]}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="grid_two_in">
                    {place?.photos?.[2] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${place.photos[2]}`}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="grid_two_main">
                  <div className="grid_two_in">
                    {place?.photos?.[3] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${place.photos[3]}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="grid_two_in">
                    {place?.photos?.[4] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${place.photos[4]}`}
                        alt=""
                      />
                    )}
                    <span onClick={() => setShowAllPhotos(true)}>
                      <div className="more_pic">
                        <AppsIcon style={{ fontSize: "medium" }} />{" "}
                        <span>Show all</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostProfile;
