import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import "./hostRegister.scss";

const HostRegister = () => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeCode, setActiveCode] = useState("");
  const [phone, setPhone] = useState("");
  const [rePassword, setRePassword] = useState("");

  // ! buuton
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const x = e.pageX - e.target.offsetLeft;
    const y = e.pageY - e.target.offsetTop;
    setCoordinates({ x, y });
  };

  const navigate = useNavigate();

  const ragisterUser = async (ev) => {
    ev.preventDefault();
    try {
      const response = await axios.post("/ragistation", {
        fName,
        lName,
        email,
        password,
      });

      localStorage.setItem("userData", JSON.stringify(response.data));
      navigate("/active");
    } catch (e) {
      console.log("Message:", "User registration failed. Please try again.");
    }
  };

  // console.log(activeCode);

  return (
    <div>
      <div className="h_login">
        <div className="h_log_in">
          <>
            <div className="h_in_h">
              <span>Earn more with consistent bookings</span>
              <span>Continue your registration</span>
            </div>
            <form onSubmit={ragisterUser}>
              <div className="input_form">
                <div>
                  <label>First Name</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="John "
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Doe"
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
              </div>
              <label>E mail</label>
              <input
                className="input"
                type="email"
                placeholder="your@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Phone</label>
              <input
                className="input"
                type="Number"
                placeholder="+940123456789"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label>Password</label>
              <input
                className="input"
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>
                Re Password -
                {password !== rePassword && (
                  <small style={{ color: "red" }}>
                    {" "}
                    Passwords do not match
                  </small>
                )}
              </label>
              <input
                className="input"
                type="password"
                placeholder="Re-enter password"
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                style={{
                  borderColor:
                    password === rePassword
                      ? "rgba(170, 170, 170, 0.6666666667)"
                      : "red",
                }}
              />

              <button
                className="button"
                onMouseMove={handleMouseMove}
                style={{
                  "--x": `${coordinates.x}px`,
                  "--y": `${coordinates.y}px`,
                }}
              >
                Register
              </button>
              <div className="h_rg_pg">
                <span>
                  Already a member?{" "}
                  <Link style={{ color: "#40c0f0" }} to={"/login"}>
                    {" "}
                    Login
                  </Link>
                </span>
              </div>
            </form>
          </>
        </div>
        
      </div>
    </div>
  );
};

export default HostRegister;
