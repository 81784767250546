import React from 'react'
import "./newsLetter.scss";
import { Link } from 'react-router-dom';

const NewLetter = () => {
  return (
    <div className="__fw gradient" style={{ backgroundColor: "#0068cf" }}>
      <div div className="__mw">
        <div className="newsLetter">
          <h2>Save time, save money!</h2>
          <span>Sign up and we'll send the best deals to you</span>
          <form>
            <div className="form_in">
              <input type="text" placeholder="Your Email" />
              <button>Subscribe</button>
            </div>
          </form>
          <small>
            See our <Link style={{ color: "#ffa500" }}>privacy statement.</Link>
          </small>
        </div>
      </div>
    </div>
  );
}

export default NewLetter
