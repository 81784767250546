import React from 'react'
import "./planNav.scss";
import { Link } from 'react-router-dom';

const PlanNav = () => {
  return (
    <div className="_nfw " style={{ backgroundColor: "#0068cf" }}>
      <div className="_nmw ">
        <section>
          <Link to={"/"} >
            <div className="_logo">
              <img src={require("../../img/logo.png")} alt="" />
            </div>
          </Link>
        </section>
      </div>
    </div>
  );

}

export default PlanNav
