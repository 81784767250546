export const tourData = {
  Bali: {
    title: "Bali",
    description:
      "Bali, often referred to as the Island of the Gods, offers a unique blend of stunning natural beauty, rich cultural heritage, and adventurous activities. Whether you're seeking relaxation on pristine beaches, exploring lush jungles, or immersing yourself in local traditions, Bali has something for every traveler.",

    img_1: require("../../img/tirta-gangga.jpeg"),
    img_2: require("../../img/TanahLot.jpg"),
    img_3: require("../../img/beach.jpg"),
    img_4: require("../../img/Waterbom.jpeg"),
    img_5: require("../../img/Kelingking.jpg"),
    city: "Bali beach, Tirta Gangga, Waterbom Bali, Kelingking Beach, Tanah Lot",
  },

  Singapore: {
    manTitle:
      "Explore the Wonders of Singapore: A Journey of Adventure and Wonder",
    title: "Singapore",
    description:
      "Singapore, a dynamic city-state, blends modernity with tradition, offering a rich tapestry of experiences. From futuristic architecture and world-class shopping to lush gardens and vibrant cultural districts, Singapore has something for every traveler. Embark on your next adventure and discover the unique charm of this cosmopolitan oasis.",

    img_1: require("../../img/studio.jpg"),
    img_2: require("../../img/zoo.webp"),
    img_3: require("../../img/sea-aquarium.webp"),
    img_4: require("../../img/CloudForest.webp"),
    img_5: require("../../img/singapore flyer.jpg"),
    city: "Universal Studios Singapore, Singapore Flyer, S.E.A. Aquarium,Cloud Forest, Singapore Zoo",
  },

  Malaysia: {
    title: "Malaysia",
    description:
      "Malaysia, a melting pot of cultures and landscapes, invites travelers to explore its diverse offerings. From the bustling cities and historical landmarks to serene islands and verdant rainforests, Malaysia promises an unforgettable adventure. Whether you're diving into its culinary delights, trekking through ancient jungles, or soaking up the vibrant local culture, your next great journey awaits in Malaysia.",

    img_1: require("../../img/Petronas-Twin-Towers.jpeg"),
    img_2: require("../../img/Pinang.jpeg"),
    img_3: require("../../img/BatuCaves.jpeg"),
    img_4: require("../../img/ESCAPE.jpeg"),
    img_5: require("../../img/Langkawi.jpeg"),
    city:"Batu Caves, Petronas Twin Towers, Langkawi Skybridge Cable Car, ESCAPE Penang, Pinang Peranakan Mansion"
  },
  Thailand: {
    title: "Thailand",
    description:
      "Thailand, known as the Land of Smiles, offers a captivating blend of natural beauty, rich history, and vibrant culture. From the bustling streets of Bangkok and the ancient temples of Chiang Mai to the stunning beaches of Phuket and the lush jungles of the north, Thailand promises a diverse array of adventures. Embark on your next journey and uncover the enchanting allure of Thailand.",

    img_1: require("../../img/White-Temple.jpeg"),
    img_2: require("../../img/Temple.jpeg"),
    img_3: require("../../img/The-Grand-Palace.jpeg"),
    img_4: require("../../img/Bangkok-&-Phuket.jpeg"),
    img_5: require("../../img/Safari-World-Bangkok.avif"),
    city: "The Grand Palace, The Temple of the Emerald Buddha, Safari World Bangkok, Wat Rong Khun - White Temple, The Temple of the Emerald Buddha, Pattaya",
  },
};
