import React, { useContext, useEffect, useRef, useState } from "react";
import "./header.scss";
import HotelIcon from "@mui/icons-material/Hotel";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ParkIcon from "@mui/icons-material/Park";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import MopedIcon from "@mui/icons-material/Moped";
import image from "../../img/banner.jpeg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BoyIcon from "@mui/icons-material/Boy";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/SearchContext";
import { FaPeopleRoof } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { MdHotel } from "react-icons/md";
import TextSlide from "../textSlide/TextSlide";
import { MdOutlineFlight } from "react-icons/md";
import countries from "./countries.js"
import { BsFillPeopleFill } from "react-icons/bs";



const Header = () => {
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);
//  const [dates, setDates] = useState([
//    { startDate: new Date(), endDate: new Date(), key: "selection" },
//  ]);
  // const [destination, setDestination] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    infants: 0,
  });

   const [dates, setDates] = useState([
     {
       startDate: new Date(),
       endDate: new Date(),
       key: "selection",
     },
   ]);


  //! SEARCH BOX 

    const [destination, setDestination] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef(null);

    // const handleDateChange = (item) => {
    //   setDates([item.selection]);
    //   setOpenDate(false); // Auto close the date picker after selecting a date
    // };

    const handleDateChange = (ranges) => {
      const { selection } = ranges;
      setDates([selection]);
    };

    const handleRangeFocusChange = (ranges) => {
      if (ranges[1] === 0) {
        // Focus has shifted to the end date, indicating selection of the end date
        setOpenDate(false);
      }
    };

    const handleChange = (e) => {
      const value = e.target.value;
      setDestination(value);
      if (value) {
        const filtered = countries.filter((country) =>
          country.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCountries(filtered);
        setShowDropdown(true);
      } else {
        setFilteredCountries([]);
        setShowDropdown(false);
      }
    };

    const handleSelect = (country) => {
      setDestination(country);
      setFilteredCountries([]);
      setShowDropdown(false);
    };

   

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };
  const { dispatch } = useContext(SearchContext);

    const ref = useRef();

  const handleSearch = () => {
    dispatch({ type: "NEW_SEARCH", payload: { destination, dates, options } });
    navigate("/listings", { state: { destination, dates, options } });
  };

 useEffect(() => {
   const handleClickOutside = (event) => {
     if (ref.current && !ref.current.contains(event.target)) {
       setOpenOptions(false);
     }
   };

   document.addEventListener("mousedown", handleClickOutside);
   return () => {
     document.removeEventListener("mousedown", handleClickOutside);
   };
 }, [ref]);

  return (
    <div
      className="back_img"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="header">
        {/* <div className="headerList">
          <Link className="nav_link" to={"/"}>
            <div className="headerListItem active">
              <HotelIcon />
              <span>Stays</span>
            </div>
          </Link>

          <Link className="nav_link">
            <div className="headerListItem">
              <ParkIcon />
              <span>Attraction</span>
            </div>
          </Link>

          <Link className="nav_link">
            <div className="headerListItem">
              <FastfoodIcon />
              <span>Dining</span>
            </div>
          </Link>
          <Link className="nav_link" to={"/airport-taxi"}>
            <div className="headerListItem">
              <DirectionsCarIcon />
              <span>Airport Taxi</span>
            </div>
          </Link>

          <Link className="nav_link" to={"/flights"}>
            <div className="headerListItem">
              <MdOutlineFlight
                size={23}
                style={{ transform: "rotate(35deg)" }}
              />
              <span>Flight</span>
            </div>
          </Link>
        </div> */}
        <div className="headerDetails">
          <h1>
            {" "}
            <TextSlide />{" "}
          </h1>
          {/* <p>Choose from cabins, houses, and more</p> */}
        </div>

        <div className="headerSreach">
          <div className="headerSreach_in">
            <div className="headerSearchItem">
              <div className="_icon">
                <MdHotel />
              </div>
              <input
                className="_ noBorder"
                type="text"
                value={destination}
                onChange={handleChange}
                placeholder="Sheach your next stay ..."
              />
              {showDropdown && filteredCountries.length > 0 && (
                <ul>
                  {filteredCountries.slice(0, 5).map((country, index) => (
                    <li
                      key={index}
                      style={{
                        padding: "8px",
                        color: "black",
                        cursor: "pointer",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                      onClick={() => handleSelect(country)}
                    >
                      {country}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div
              className="headerSearchItem"
              onClick={() => setOpenDate(!openDate)}
            >
              <div className="_icon">
                <MdDateRange />
              </div>
              <span className="headerSearchText">
                <em>CheckIn</em> {`${format(dates[0].startDate, "dd/MM/yyyy")}`}
                <em> - </em>
                <em>CheckOut</em> {`${format(dates[0].endDate, "dd/MM/yyyy")}`}
              </span>
              {openDate && (
                <div
                  className="datePicker"
                  onClick={(e) => e.stopPropagation()}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={true}
                    ranges={dates}
                    className="date"
                    minDate={new Date()}
                    onRangeFocusChange={handleRangeFocusChange}
                  />
                </div>
              )}
            </div>

            <div className="headerSearchItem" ref={ref}>
              <div className="_icon">
                <BsFillPeopleFill />
              </div>
              <span
                className="headerSerachText"
                onClick={() => setOpenOptions(!openOptions)}
              >
                {`${options.adult} Adult · ${options.children} Children · ${options.infants} Infants`}
              </span>
              {openOptions && (
                <div className="options">
                  <div className="optionItem">
                    <span className="optionText">Adult</span>
                    <div className="optionCounter">
                      <button
                        disabled={options.adult <= 1}
                        className="optionCounterButton"
                        onClick={() => handleOption("adult", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.adult}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("adult", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="optionItem">
                    <span className="optionText">Children</span>
                    <div className="optionCounter">
                      <button
                        disabled={options.children <= 0}
                        className="optionCounterButton"
                        onClick={() => handleOption("children", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.children}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("children", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="optionItem">
                    <span className="optionText">Infants</span>
                    <div className="optionCounter">
                      <button
                        disabled={options.infants <= 0}
                        className="optionCounterButton"
                        onClick={() => handleOption("infants", "d")}
                      >
                        -
                      </button>
                      <span className="optionCounterNumber">
                        {options.infants}
                      </span>
                      <button
                        className="optionCounterButton"
                        onClick={() => handleOption("infants", "i")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="_headerSearchItem">
              <button className="_button_sh" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="backdrop"></div>
    </div>
  );
};

export default Header;
