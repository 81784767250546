import React, { useContext, useState, useEffect } from "react";
import Button from "./Button";
import menuItems from "./MenuItems";
import "./NavbarMob.css";
import { TbGridDots } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const NavbarMob = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const { user, dispatch } = useContext(AuthContext);

  const firstName = user ? user.fName?.charAt(0) : "";
  const role = user ? user.role : "";

  const handleClick = () => {
    setActive(!active);
  };

  const closeMenu = () => {
    setActive(false);
  };

  const getProfileRoute = () => {
    if (role === "host") {
      return "/hosting";
    } else if (role === "admin") {
      return "/today-bookings";
    } else if (role === "guest") {
      return "/user-profile";
    } else {
      return "/login";
    }
  };

  const logoutUser = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await axios.get("/logout");
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
    return () => controller.abort();
  };

  useEffect(() => {
    if (active) {
      document.body.classList.add("body-no-scroll");
    } else {
      document.body.classList.remove("body-no-scroll");
    }

    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [active]);

  return (
    <nav className="mobileNavbar">
      <div className="top_nav">
        <div className="navbar-logo">
          <Link to={"/"}>
            <img src={require("../../img/logo.png")} alt="Logo" />
          </Link>
        </div>
        <div className="icon_wrp">
          <div className="menu-icon">
            {user && (
              <Link to={getProfileRoute()}>
                <div className="__profile">
                  <div className="pro_name">
                    <span>{firstName}</span>
                  </div>
                </div>
              </Link>
            )}
          </div>
          <div className="menu_icon_wrp" onClick={handleClick}>
            {active ? <IoClose color="white" /> : <TbGridDots color="white" />}
          </div>
        </div>
      </div>

      <ul className={active ? "nav-menu active" : "nav-menu"}>
        {menuItems.map((item, index) => {
          return (
            <li className="nav_link" key={index}>
              <Link to={item.url} className={item.cName} onClick={closeMenu}>
                {item.title}
              </Link>
            </li>
          );
        })}

        <div className="nav-links-mobile" onClick={closeMenu}>
          <div className="nav-sec">
            <Link to={getProfileRoute()}>
              <div className="__n_login">
                {user ? (
                  <div className="__profile_login">
                    <span onClick={logoutUser}>Logout</span>
                  </div>
                ) : (
                  <div className="_profile">
                    <Link to={"/register"}>
                      <span>Sing Up</span>
                    </Link>
                    <span style={{ color: "#222222" }}>|</span>
                    <div>
                      <span onClick={logoutUser}>Login</span>
                    </div>
                  </div>
                )}
              </div>
            </Link>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default NavbarMob;
