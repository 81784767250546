import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import HostNav from "../../../components/hostNav/HostNav";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import useFetch from "../../../hooks/useFetch";
import { MdOutlineEdit } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Listings = () => {
  // const {id} = useParams();
  const { user } = useContext(AuthContext);
  const id = user.id;
  const [availability, setAvailability] = useState();
  const [showAddNew, setShowAddNew] = useState(false);
  const [itemEditId, setItemEditId] = useState("");
  const [response, setResponse] = useState("");
  
  const { data, loading, error, reFetch } = useFetch(`/getaprovedPlace/${id}`);

  const onEditClick = (id) => {
    const data = id;
    setItemEditId(data);
    setShowAddNew(!showAddNew);
  };

  // ! select Listing
  const handleListingChange = (event) => {
    setAvailability(event.target.value);
  };
  const handleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const listData = {
      availability,
      itemEditId: itemEditId[0],
      ownerId: itemEditId[1],
    };
    try {
      await axios.put("/availability", listData).then((response) => {
        setResponse(response.data);
      });
      setShowAddNew("");
      setItemEditId("");
      reFetch()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="listings">
          <div className="listings_head">
            <div className="listings_item_text">
              <h1>Your listing</h1>
            </div>
            {user.role === "host" && (
              <>
                <Link to={`/listings/${id}/newplace`}>
                  <div className="listings_item">
                    <h1>
                      <AddIcon fontSize="20" />
                    </h1>
                  </div>
                </Link>
              </>
            )}
          </div>
          <div className="addNew">
            {showAddNew && (
              <div className="addNew_in">
                <div className="addNew_wrap">
                  <div className="_head">
                    <h3>Availability</h3>
                  </div>

                  {/* <p>{itemEditId}</p> */}
                  <br />
                  <form onSubmit={handleSubmit}>
                    <div className="add_form">
                      <div className="add_form_wrap">
                        <div className="_item">
                          <div>
                            <h3>Available</h3>
                          </div>
                          <label>
                            <input
                              type="radio"
                              checked={availability === "true"}
                              name="availability"
                              value="true"
                              onChange={handleListingChange}
                            />
                          </label>
                        </div>
                        <div className="_item">
                          <div>
                            <h3>Not Available</h3>
                          </div>
                          <label>
                            <input
                              type="radio"
                              checked={availability === "false"}
                              name="availability"
                              value="false"
                              onChange={handleListingChange}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="save">
                        <button className="_btn">
                          <span> Update</span>
                        </button>
                      </div>
                    </div>

                    <div className="close">
                      <span onClick={handleAddNew}>
                        <IoIosCloseCircleOutline size={22} />
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          
          <div className="listing_list">
            <div className="list_in">
              {data.map((item) => (
                <div key={item.listingId} className="place_card">
                  <div className="place_img">
                    {item.photos?.length > 0 && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${item.photos}`}
                        alt=""
                      />
                    )}

                    <div className="approved">
                      <div className="approved_icon"> </div>
                      <span>verified</span>
                    </div>
                  </div>
                  <div className="place_info">
                    <div className="place_one">
                      <h2>{item.title}</h2>
                    </div>
                    <div className="place_four">
                      <span>
                        Rs. {item.price} <em>night</em>
                      </span>
                    </div>
                    <div className="icon_edit_wrap">
                      <span
                        className="icon_edit"
                        onClick={() =>
                          onEditClick([item.listingId, item.owner])
                        }
                        // onClick={handleAddNew}
                      >
                        <MdOutlineEdit size={20} color="gray" />
                      </span>
                    </div>
                    {item.availability === "false" && (
                      <div className="_booking">
                        <span>Booking Going on </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listings;
