import React, { useContext, useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import moment from "moment";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./eventCalendar.scss";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import HostNav from "../../../components/hostNav/HostNav";


moment.locale("en-GB");
const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const EventCalendar = () => {
  const { user } = useContext(AuthContext);
  const ownerId = user.id;
  const [guestBooking, setGuestBooking] = useState([]);


  useEffect(() => {
 
const cancelToken = axios.CancelToken.source();
    axios
      .get(`/getGuestBooking/${ownerId}`, { cancelToken: cancelToken.token })
      .then((response) => {
        const guestList = response.data;
        const bookingPlace = guestList.bookings;
        setGuestBooking(bookingPlace);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });

 return () => {
   cancelToken.cancel();
 };
  }, [ownerId]);

  const myEventsList = guestBooking.map((booking) => ({
    title: ` ${booking.name},- Rs ${booking.price}, - ${booking.placeName}`,
    start: new Date(booking.checkIn),
    end: new Date(booking.checkOut),
  }));

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="calendar">
          <Calendar
            localizer={localizer}
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(event, start, end, isSelected) => ({
              style: {
                // backgroundColor: isSelected ? "#3174ad" : "var(--darkColor)", // Adjust the background color based on selection
                color: isSelected ? "#007193 " : "var(--darkColor)", // Adjust the text color based on selection
                fontSize: "12px",
                textTransform: "capitalize",
                fontWeight: 600,
              },
            })}
            style={{ height: 500 }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventCalendar;
