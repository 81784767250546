import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import "./register.scss";
import RegAccount from "../../components/regAccount/RegAccount";

const Register = () => {
 

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="bg-tex">
          <div class="ls-Background-background">
            <div class="ls-ls-Background-background"></div>
            <div
              class="ls-Background-background-s-left"
              style={{ background: "#0073ff" }}
            ></div>
            <div
              class="ls-Background-background-f-s-rihgt"
              style={{ background: "#ffb700" }}
            ></div>
          </div>
        </div>
        <div className="r_login">
          <div className="r_log_in">
            <>
              <div className="r_in_h">
                <img src={require("../../img/bk_logo.png")} alt="" />
                <p>
                  <em>Sign up</em>
                </p>
              </div>
              <RegAccount />
              <div className="r_rg_pg">
                <span>
                  Already a member?{" "}
                  <Link style={{ color: "#40c0f0" }} to={"/login"}>
                    {" "}
                    Login
                  </Link>{" "}
                </span>
              </div>
            </>
            <div className="cross_mark">
              <div className="r_icon">
                <Link to={"/"}>
                  <ClearIcon className="icon" fontSize="20" color="black" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
