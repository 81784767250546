import React from 'react'
import background from "../../img/taxi.jpg";
import AirPortTexi from '../../components/hostRegister/AirPortTexi';

const Taxi = () => {
  return (
    <div
      className="__fw"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "80dvh",
        height: "auto",
        alignItems: "flex-start",
      }}
    >
      <div className="__mw">
        <div className="list">
          <div className="list-item">
            <h1>
              Discover seamless airport taxi services in <em>Sri Lanka</em> with
              expert assistance for a hassle-free arrival experience.
            </h1>
            <p>
              Enjoy effortless airport transfers in Sri Lanka with expert
              guidance for a smooth arrival process
            </p>
          </div>
          <div className="list-item">
            <AirPortTexi />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Taxi
