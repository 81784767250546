import React from "react";
import { AiFillHome } from "react-icons/ai";
import "./marker.css";

const Marker = () => {
  return (
    <div className="marker">
      <AiFillHome color="white" />
      <div className="marker_in"></div>
    </div>
  );
};

export default Marker;
