import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import "./profileHost.scss";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import HostNav from "../../../components/hostNav/HostNav";
import { FaUserCheck } from "react-icons/fa";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ProfileHost = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const { user } = useContext(AuthContext);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [headOne, setHeadOne] = useState("");
  const [headTwo, setHeadTwo] = useState("");
  const [headThree, setHeadThree] = useState("");
  const [profileData, setProfileData] = useState("");
  const itemEditId = user.id;
  const [docPhotos, setDocPhotos] = useState([]);

  //   const { data, error, refetch } = useFetch(`/getHostProfile/${itemEditId}`);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (!itemEditId) {
      return;
    }

    const fetchData = async () => {
      try {
        await axios
          .get(`/getHostProfile/${itemEditId}`, {
            cancelToken: cancelToken.token,
          })
          .then((response) => {
            const { data } = response;
            setFName(data.hostProfile.fName);
            setLName(data.hostProfile.lName);
            setDisplayName(data.hostProfile.displayName);
            setEmail(data.hostProfile.email);
            setPhone(data.hostProfile.phone);
            setAddress(data.hostProfile.address);
            setHeadOne(data.hostProfile.headOne);
            setHeadTwo(data.hostProfile.headTwo);
            setHeadThree(data.hostProfile.headThree);
            setProfileData(data.hostProfile);
            setDocPhotos(data.hostProfile.docPhotos);
          });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    return () => {
      if (!cancelToken.token.reason) {
        cancelToken.cancel("Request canceled by cleanup");
      }
    };
  }, [itemEditId]);

  const updateHost = async (e) => {
    e.preventDefault();
    const list = {
      fName,
      lName,
      displayName,
      email,
      phone,
      address,
      headOne,
      headTwo,
      headThree,
      itemEditId,
      docPhotos,
  
    };
    console.log(list);
    try {
      await axios.put(`/hodateHost/${itemEditId}`, list);
      //  navigate("/profile-host");
    } catch (error) {
      console.log(error);
    }
  };

  //! DOC upload

  async function uploadPhoto(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("doc", files[i]);
    }
    await axios
      .post("/docUploads", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setDocPhotos((prev) => {
          return [...prev, ...filenames];
        });
      });
  }

  function removePhoto(e, filename) {
    e.preventDefault();
    setDocPhotos([...docPhotos.filter((photo) => photo !== filename)]);
  }

  function selectMainPhoto(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = docPhotos.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setDocPhotos(newAddedPhotos);
  }

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <HostNav />
        <div className="h_user">
          <div className="h_user_section">
            <div className="h_user_left">
              <div className="h_user_left_top">
                <div className="h_user_left_top_in">
                  <div className="h_img_con">
                    <img
                      src={
                        `${process.env.REACT_APP_DOC_UPLOAD_LINK}/` +
                        docPhotos[0]
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div className="h_user_right_head">
                  <h1>
                    welcome <br />
                    <em>
                      {user.fName} {user.lName}{" "}
                    </em>
                  </h1>
                </div>
              </div>
              <div className="h_user_left_buttom">
                <div className="h_user_left_buttom_in">
                  <strong>{user.role}</strong>
                  <br />
                  <h3>confirmed information</h3>
                </div>
                <hr />

                <div className="h_user_left_buttom_center">
                  <ul>
                    <li>
                      <p>{profileData.isVerified}</p>
                    </li>
                    <li>
                      <p>{profileData.plan}</p>
                    </li>

                    <li>
                      <p>{profileData.certify}</p>
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="h_user_left_buttom_in">
                  <div className="head_">
                    <h3>Verify your Account</h3>
                  </div>
                  <div>
                    <small>
                      Before you book or host on Leisurery.com, you’ll need to
                      complete this step.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="h_user_right">
              <div className="varify_info_form">
                <form onSubmit={updateHost}>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>First name</label>
                      <input
                        disabled
                        type="text"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                      />
                    </div>
                    <div className="varify_info_form_in_data">
                      <label>Last Name</label>
                      <input
                        disabled
                        type="text"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Display name</label>
                      <small>Choose a display name</small>
                      <input
                        type="text"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Email address</label>
                      <small>
                        This is the email address you use to sign in. It’s also
                        where we send your booking confirmations.
                      </small>
                      <input
                        disabled
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Phone number</label>
                      <small>
                        Properties or attractions you book will use this number
                        if they need to contact you.
                      </small>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Address</label>
                      <small>
                        Properties or attractions you book will use this number
                        if they need to contact you.
                      </small>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>INC details</label>
                      <small>
                        Properties or attractions you book will use this number
                        if they need to contact you.
                      </small>
                      <input type="text" placeholder="Choose a display name" />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <hr />
                  </div>

                  <div className="varify_info_form_in">
                    <h2>Property page information</h2>
                  </div>

                  <p>
                    {" "}
                    Customize each section with specific details about your
                    property to make it unique
                  </p>

                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Head Line</label>
                      <small>Add headline of your property that standout</small>
                      <textarea
                        type="text"
                        value={headOne}
                        onChange={(e) => setHeadOne(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Heading Two</label>
                      <small>
                        Add information of your property that people can get to
                        know{" "}
                      </small>
                      <textarea
                        type="text"
                        value={headTwo}
                        onChange={(e) => setHeadTwo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <label>Heading Three</label>
                      <small>
                        Add information of your property that people can get to
                        know{" "}
                      </small>
                      <textarea
                        type="text"
                        value={headThree}
                        onChange={(e) => setHeadThree(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="varify_info_form_in">
                    <div className="varify_info_form_in_data">
                      <h3>Verify your property </h3>
                      <small>
                        Add your ID copy, profile picture,and property destance
                        picture
                      </small>
                    </div>
                    <div className="h_photo_grid">
                      {docPhotos?.length > 0 &&
                        docPhotos.map((link) => (
                          <div className="h_img_over" key={link}>
                            <img
                              src={
                                `${process.env.REACT_APP_DOC_UPLOAD_LINK}/` +
                                link
                              }
                              alt=""
                            />
                            <div className="h_trash_icon">
                              <button onClick={(e) => removePhoto(e, link)}>
                                <DeleteOutlineIcon
                                  style={{ fontSize: "medium" }}
                                />
                              </button>
                            </div>
                            <div className="h_star_icon">
                              <button onClick={(e) => selectMainPhoto(e, link)}>
                                {link === docPhotos[0] && (
                                  <StarIcon
                                    style={{
                                      fontSize: "medium",
                                      color: "var(--orange)",
                                    }}
                                  />
                                )}
                                {link !== docPhotos[0] && (
                                  <StarBorderIcon
                                    style={{ fontSize: "medium" }}
                                  />
                                )}
                              </button>
                            </div>
                          </div>
                        ))}
                      <label className="label">
                        <input
                          onChange={uploadPhoto}
                          type="file"
                          multiple
                          className="btn_upload"
                        />
                        Upload
                      </label>
                    </div>
                  </div>
                  <button className="h_btn" onClick={scrollToTop}>
                    <span>Save</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHost;
