const products = [
  {
    propertie: 8 ,
    name: "Matara",
    description: "Description of Product 1",
    image:
      "https://seylontour.com/static/media/womenTour.f2e9096ce3db123589d4.jpg",
  },
  {
    propertie: 28,
    name: "Colombo",
    description: "Description of Product 2",
    image: "https://seylontour.com/static/media/ADV.46bbe2ffa53fc134853c.jpg",
  },
  {
    propertie: 4,
    name: "Galle",
    description: "Description of Product 2",
    image: "https://seylontour.com/static/media/VIP.95c81ab2215db5f64fd2.jpg",
  },
  {
    propertie: 30,
    name: "Mirissa",
    description: "Description of Product 2",
    image:
      "https://seylontour.com/static/media/soloADV.0ff315a5a70776a5be5f.jpg",
  },
  {
    propertie: 5,
    name: "Kandy",
    description: "Description of Product 2",
    image:
      "https://seylontour.com/static/media/sqr_banner9.5893ea9d6486348fb487.jpg",
  },
  {
    propertie: 10,
    name: "Sigiriya",
    description: "Description of Product 2",
    image:
      "https://seylontour.com/static/media/sqr_banner4.a499ecc15092f34abab1.jpg",
  },
  {
    propertie: 8,
    name: "Polonnaruwa",
    description: "Description of Product 2",
    image:
      "https://seylontour.com/static/media/sqr_banner_jaffna.80efc72dcda2c16ab3fe.jpg",
  },
  {
    propertie: 20,
    name: "Jaffna",
    description: "Description of Product 2",
    image:
      "https://seylontour.com/static/media/sqr_banner_Mirissa.e301209b8902d46ae274.jpg",
  },
  // Add more products as needed
];

export default products