import React from 'react'
import "./listProperty.scss";
import background from "../../img/hoat.jpg";
import HostRegister from '../../components/hostRegister/HostRegister';
import OfferPkg from '../../components/offerPkg/OfferPkg';


const ListProperty = () => {
  return (
    <div className="__fw">
      <div
        className="__fw"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "80dvh",
        }}
      >
        <div className="__mw">
          <div className="list">
            <div className="list-item">
              <h1>
                List your{" "}
                <em>
                  apartment, holiday, home, guest house, bed and breakfast
                </em>{" "}
                on Leisurery.com
              </h1>
              <p>
                Whether hosting is your side passion or full-time job, register
                your holiday rental on Booking.com to reach travellers worldwide
              </p>
            </div>
            <div className="list-item">
              <HostRegister />
            </div>
          </div>
        </div>
      </div>
      <div className="__fw">
        <div className="__mw">
          <OfferPkg />
        </div>
      </div>
    </div>
  );
}

export default ListProperty
