import React from "react";
import "./offerPkg.scss";
import { HiCheckCircle } from "react-icons/hi";
import { RxCrossCircled } from "react-icons/rx";

import { FaCheck } from "react-icons/fa6";
import { CgClose } from "react-icons/cg";

const OfferPkg = () => {
  return (
    <>
      <section className="pricing-plans">
        <div className="pricing-plans-head">
          <h1>Choose the plan that fits you, and only you.</h1>
          <small>
            Tailored Just for You: Find the Perfect Plan to Meet Your Unique
            Needs and Lifestyle Preferences
          </small>
        </div>

        <div className="pricing__table">
          <div className="pt__title">
            <div className="pt__title__wrap">
              <div className="pt__row"></div>
              <div className="pt__row">Gold</div>
              <div className="pt__row">Platinum</div>
              <div className="pt__row">Free</div>
            </div>
          </div>
          <div className="pt__option">
            <div className="pt__option__slider swiper" id="pricing-card-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide pt__option__item ">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>
                          List your unlimited number of event/property or any on
                          the leisury database
                        </p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Access to event management dashboard</small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Verified Basic Listing Details</p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Feedback Approval Access</small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>
                          You may choose to promote/upgrade your Listening at
                          any point
                        </p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Simple travel reporting</small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>commission per each booking</p>
                      </div>
                      <div className="pt__row">10%</div>
                      <div className="pt__row">7.5%</div>
                      <div className="pt__row">12.5%</div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Priority Email Support</small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Marketing Campaigns to reach targeted audience</p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>
                          Behavioral analysis of Marketing Campaigns
                        </small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Promotion Coverage on leisury Social Channels</p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>
                          Feature Showcase on Targeted City Search Pages
                        </small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Email marketing</p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Facebook and Instagram ads</small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Guest review</p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>24/7 Chat support</small>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Promotion during exhibitions</p>
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Featured slot on homepage</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>
                          Featured slot in keyword based search & profession
                          based listings
                        </p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Get coverage in media / blogs</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>
                          Featured organizer branding on homepage to improve
                          brand recall
                        </p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Send Custom Reminders and Emails</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Phone support</p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Google advertising</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>SEO Promotion</p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Promo codes</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Owner Personal profile(website Page)</p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Traffic and conversion reporting</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>If a guest cancels, we’ll look for a replacement</p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Blog post Promoting Your Locality</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide pt__option__item">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <p>Tailored marketing strategies</p>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide pt__option__item bk__color">
                  <div className="pt__item">
                    <div className="pt__item__wrap">
                      <div className="pt__row">
                        <small>Online Marketing Mentorship</small>
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                      <div className="pt__row">
                        <FaCheck />
                      </div>
                      <div className="pt__row">
                        <CgClose />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="swiper-slide pt__option__item">
                  <div className="pt__item selected">
                    <div className="pt__item__wrap">
                      <div className="pt__row">Get started</div>
                      <div className="pt__row">
                        <a href="">Purchase Now</a>
                      </div>
                      <div className="pt__row">
                        <a href="">Purchase Now</a>
                      </div>
                      <div className="pt__row">
                        <a href="">Purchase Now</a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferPkg;
