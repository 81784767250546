import React, { useState } from 'react'
import products from './products.js';
import './carousel.css'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from 'react-router-dom';

const CityCarousel = () => {
    const options = {
      loop: true,
      margin: 10,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    };

  return (
    <div className="carousel-wrapper owl-carousel owl-theme owl-loaded">
      <OwlCarousel className="owl-theme" {...options}>
        {products.map((product, index) => (
          <div className="item" key={index}>
            <Link to={"/"}>
              <div className="card_wrap">
                <div className="card_img">
                  <img src={product.image} alt={product.name} />
                </div>
              </div>
              <div className="card_info">
                {/* <span>{product.name}</span>
                <small>{product.propertie} propertie</small> */}
              </div>
            </Link>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}


export default CityCarousel
