const menuItems = [
  {
    title: "Stays",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Attraction",
    url: "/",
    cName: "nav-links",
  },
  // {
  //   title: "Dining",
  //   url: "#",
  //   cName: "nav-links",
  // },
  {
    title: "Airport Taxi",
    url: "/airport-taxi",
    cName: "nav-links",
  },
  {
    title: "Flight",
    url: "/flights",
    cName: "nav-links",
  },
  {
    title: "List your property",
    url: "/list-your-property",
    cName: "nav-links",
  },

];

export default menuItems;
