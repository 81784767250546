import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Host from "./pages/admin-host/host/Host";
import Error from "./pages/error/Error";
import Listings from "./pages/admin-host/listings/Listings";
import Navbar from "./components/navBar/Navbar";
import AddNewPlace from "./pages/admin-host/addNewPlace/AddNewPlace";
import SingalPlace from "./pages/singalPlace/SingalPlace";
import ListProperty from "./pages/listProperty/ListProperty";
import { ProtectedRoute } from "./util/ProtectedRoute";
import BookingPage from "./pages/admin-guest/bookingPage/BookingPage";
import HostProfile from "./pages/admin-host/hostProfile/HostProfile";
import List from "./components/list/List";
import ProfileHost from "./pages/admin-host/profileHost/ProfileHost";
import ActiveCode from "./pages/admin-host/activeCode/ActiveCode";
import Flight from "./pages/flight/Flight";
import Taxi from "./pages/flight/Taxi";
import EventCalendar from "./pages/admin-host/calendar/EventCalendar";
import Inbox from "./pages/admin-host/inbox/Inbox";
import UsersProfile from "./pages/admin-guest/guestPage/UsersProfile";
import OutTours from "./pages/outTours/OutTours";
import Booking from "./pages/book/Booking";
import SuccessPay from "./components/success/SuccessPay";
import NavbarMob from "./components/mbileNavBar/NavbarMob";

// axios.defaults.baseURL = "http://localhost:5000/api/v1";
axios.defaults.baseURL = "https://api.leisurery.com/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isLoginOrRegister =
    location.pathname === "/login" ||
    location.pathname === "/book/stays" ||
    location.pathname === "/register" ||
    location.pathname === "/success" ||
    location.pathname.startsWith("/about-host/");

  return (
    <>
      {isMobile ? <NavbarMob /> : !isLoginOrRegister && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/listings" element={<List />} />
        <Route path="/active" element={<ActiveCode />} />
        <Route path="/flights" element={<Flight />} />
        <Route path="/airport-taxi" element={<Taxi />} />
        <Route path="/place/:listingId" element={<SingalPlace />} />
        <Route
          path="/about-host/:listingId/:hostId"
          element={<HostProfile />}
        />
        <Route path="/list-your-property" element={<ListProperty />} />
        <Route path="/tour/:place" element={<OutTours />} />

        <Route
          path="/hosting"
          element={
            <ProtectedRoute>
              <Host />
            </ProtectedRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <ProtectedRoute>
              <EventCalendar />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile-host"
          element={
            <ProtectedRoute>
              <ProfileHost />
            </ProtectedRoute>
          }
        />

        <Route
          path="/listings/:id"
          element={
            <ProtectedRoute>
              <Listings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listings/:id/newplace"
          element={
            <ProtectedRoute>
              <AddNewPlace />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listings/:id/newplace/:listingId"
          element={
            <ProtectedRoute>
              <AddNewPlace />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox/:id"
          requiredRole="host"
          element={
            <ProtectedRoute>
              <Inbox />
            </ProtectedRoute>
          }
        />

        <Route
          path="/user-profile"
          requiredRole="guest"
          element={
            <ProtectedRoute>
              <UsersProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/booking/:userId/:bookingId/:placeId"
          requiredRole="guest"
          element={
            <ProtectedRoute>
              <BookingPage />
            </ProtectedRoute>
          }
        />

        <Route path="/book/stays" element={<Booking />} />
        <Route path="/book/stays" element={<Booking />} />
        <Route path="/success" element={<SuccessPay />} />
        <Route path="*" element={<Error />} />
      </Routes>
      {!isLoginOrRegister && <Footer />}
    </>
  );
};

export default App;
