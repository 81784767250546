import React, { useEffect } from "react";
import "./outTours.scss";
import { tourData } from "./tourData.js";
import { useParams } from "react-router-dom";
import TourBook from "../../components/hostRegister/TourBook.jsx";
import { AiFillCheckSquare } from "react-icons/ai";

const OutTours = () => {
  const { place } = useParams();
  const tour = tourData[place];

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="_out_tour">
          <div className="tour_one">
            <div className="tour_one_top">
              <div class="_header">
                <div class="header_in">
                  {/* <span>top citie</span> */}
                  <h1>
                    Discover{" "}
                    <em>
                      {" "}
                      your next <br /> travel{" "}
                    </em>{" "}
                    adventure in {tour.title}
                  </h1>
                  <p>{tour.description}</p>
                </div>
              </div>
            </div>
            <div className="tour_one_bottom">
              <div className="tour_b_one ">
                <img src={tour.img_1} alt="" />
              </div>
              <div className="tour_b_two ">
                <img src={tour.img_2} alt="" />
              </div>
            </div>
          </div>
          <div className="tour_two ">
            <img src={tour.img_3} alt="" />
            <div className="tour_info"></div>
          </div>
        </div>
        <div></div>

        <div className="_out_tour_form">
          <div>
            <TourBook />
          </div>
          <div className="tour_one_bottom">
            <div className="tour_head_top">
              <div className="tour_head_top_one">
                <h1>
                  Top <em>5 places</em> to see{" "}
                </h1>
              </div>
              <div className="tour_head_top_two">
                <h3>{tour.city}</h3>
                <br />
                <small>
                  Experience the best of Singapore with thrilling rides at
                  Universal Studios, panoramic city views from the Singapore
                  Flyer, marine wonders at S.E.A. Aquarium, lush greenery in
                  Cloud Forest, and diverse wildlife at Singapore Zoo.
                </small>
              </div>
            </div>
            <div className="tour_card">
              <div className="tour_b_one">
                <img src={tour.img_4} alt="" />
              </div>

              <div className="tour_b_two">
                <img src={tour.img_5} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tour_buttom">
          <div className="tour_card">
            <h2>{tour.text_4_head}</h2>
            <p>{tour.text_4_info}</p>
          </div>
          <div className="tour_card">
            <h2>{tour.text_5_head}</h2>
            <p>{tour.text_5_info}</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OutTours;
