import React from "react";
import "./Itinerary.scss";

const Itinerary = ({selected, onChange}) => {
  const handleCkeck = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      onChange([...selected, name]);
    } else {
      onChange([...selected.filter((selecteName) => selecteName !== name)]);
    }
  };

  return (
    <>
      <div className="_iten">
        <span>
          <div className="item_img">
            <img src={require("../../img/03.webp")} alt="" />
            <p>WIFI</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("wifi")}
            name="wifi"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/14.webp")} alt="" />
            <p>Tv</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Tv")}
            name="Tv"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/02.webp")} alt="" />
            <p>Kitchen</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Kitchen")}
            name="Kitchen"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/10.webp")} alt="" />
            <p>Washer</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Washer")}
            name="Washer"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/05.webp")} alt="" />
            <p>Free parking on premises</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Free parking on premises")}
            name="Free parking on premises"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/17.webp")} alt="" />
            <p>Air conditioning</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Air conditioning")}
            name="Air conditioning"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/04.webp")} alt="" />
            <p>Dedicated workspace</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Dedicated workspace")}
            name="Dedicated workspace"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/06.webp")} alt="" />
            <p>Pool</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Pool")}
            name="Pool"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/09.webp")} alt="" />
            <p>Hot tub</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Hot tub")}
            name="Hot tub"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/44.png")} alt="" />
            <p>Patio</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Patio")}
            name="Patio"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/43.png")} alt="" />
            <p>BBQ grill</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("BBQ grill")}
            name="BBQ grill"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/28.webp")} alt="" />
            <p>Outdoor dining area</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Outdoor dining area")}
            name="Outdoor dining area"
            onChange={handleCkeck}
          />
        </span>

        <span>
          <div className="item_img">
            <img src={require("../../img/36.png")} alt="" />
            <p>Fire pit</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Fire pit")}
            name="Fire pit"
            onChange={handleCkeck}
          />
        </span>

        <span>
          <div className="item_img">
            <img src={require("../../img/33.webp")} alt="" />
            <p>Pool table</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Pool table")}
            name="Pool table"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/39.png")} alt="" />
            <p>Indoor fireplace</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Indoor fireplace")}
            name="Indoor fireplace"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/38.png")} alt="" />
            <p>Piano</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Piano")}
            name="Piano"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/42.png")} alt="" />
            <p>Exercise equipment</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Exercise equipment")}
            name="Exercise equipment"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/40.png")} alt="" />
            <p>Lake access</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Lake access")}
            name="Lake access"
            onChange={handleCkeck}
          />
        </span>

        <span>
          <div className="item_img">
            <img src={require("../../img/37.png")} alt="" />
            <p>Beach access</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Beach access")}
            name="Beach access"
            onChange={handleCkeck}
          />
        </span>

        <span>
          <div className="item_img">
            <img src={require("../../img/41.png")} alt="" />
            <p>Outdoor shower</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Outdoor shower")}
            name="Outdoor shower"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/32.webp")} alt="" />
            <p>Smoke alarm</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Smoke alarm")}
            name="Smoke alarm"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/21.webp")} alt="" />
            <p>First aid kit</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("First aid kit")}
            name="First aid kit"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/20.png")} alt="" />
            <p>Fire extinguisher</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Fire extinguisher")}
            name="Fire extinguisher"
            onChange={handleCkeck}
          />
        </span>
        <span>
          <div className="item_img">
            <img src={require("../../img/19.webp")} alt="" />
            <p>Carbon monoxide alarm</p>
          </div>
          <input
            type="checkbox"
            checked={selected.includes("Carbon monoxide alarm")}
            name="Carbon monoxide alarm"
            onChange={handleCkeck}
          />
        </span>
      </div>
    </>
  );
};

export default Itinerary;
