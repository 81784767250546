import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./booking.scss";
import { IoIosArrowRoundForward } from "react-icons/io";
import PlanNav from "../../components/planNav/PlanNav";
import { IoChevronBack } from "react-icons/io5";
import useFetch from "../../hooks/useFetch";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import RegBooking from "../../components/regBooking/RegBooking";
import LoginBooking from "../../components/LoginBooking/LoginBooking";
import PaymentForm from "../../components/paymentForm/PaymentForm";



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Booking = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const query = useQuery();
  const numberOfAdults = query.get("numberOfAdults");
  const numberOfChildren = query.get("numberOfChildren");
  const numberOfInfants = query.get("numberOfInfants");
  const checkIn = query.get("checkin");
  const checkOut = query.get("checkout");
  const listingId = query.get("listingId");
  // const [showCheckout, setShowCheckout] = useState(false);
  const [totaldays, setTotalDays] = useState(0);
  // const [status, setStatus] = useState(null);
  const [selectPrice, setSelectPrice] = useState(0);


  const { data} = useFetch(`/singalPlace/${listingId}`);


  // ! select Place
  const priceCal = (event) => {
    setSelectPrice(Number(event.target.value));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const generateBookingId = () => {
    const number = Date.now();
    const bID = "ls" + number;
    return bID;
  };
  const bookingNumber = generateBookingId();

  const generatePinCode = () => {
    const number = Date.now();
    const formatted = number.toLocaleString().replace(/\D/g, "").slice(0, 6);
    const pin = formatted;
    return pin;
  };
  const pinCode = generatePinCode();

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  useEffect(() => {
    if (checkIn && checkOut) {
      const diffDays = calculateDateDifference(checkIn, checkOut);
      setTotalDays(diffDays);
    }
  }, [checkIn, checkOut]);

  const totalPrice = data.salePrice * totaldays;
  const serviceFee = 99;
  const price = Number(selectPrice) + serviceFee;

  const advance = (totalPrice * 25) / 100;

  // const fetchClientSecret = useCallback(async () => {
  //   try {
  //     const body = {
  //       products: [
  //         {
  //           title: data?.title,
  //           price: price,
  //           name: user?.fName,
  //           quantity: 1,
  //         },
  //       ],
  //     };

  //     const headers = {
  //       "Content-Type": "application/json",
  //     };

  //     const response = await axios.post("/create-checkout-session", body, {
  //       headers: headers,
  //     });
  //     setClientSecret(response.data.clientSecret);
  //     setShowCheckout(true);
  //   } catch (error) {
  //     console.error("Error fetching client secret:", error);
  //   }
  // }, [data?.title, price, user?.fName]);



  const bookingPlace = async () => {
    const _data = {
      checkIn,
      checkOut,
      children: numberOfChildren,
      adults: numberOfAdults,
      infants: numberOfInfants,
      price: data?.salePrice * totaldays + serviceFee,
      name: user?.fName + " " + user?.lName,
      email: user?.email,
      country: user?.country,
      tempEmail: user?.tempEmail,
      guestId: user?.id,
      placeId: data?._id,
      placeName: data?.title,
      placeImg: data?.photos?.[0],
      placeCity: data?.city,
      placeOwnerId: data?.owner,
      bookingNumber,
      pinCode,
    };
    try {
      await axios.post("/booking", _data);
      navigate("/user-profile");
    } catch (error) {
      console.log("Booking not successful ", error);
    }
  };






  // const handlePayNow = async (retries = 3) => {
  //   try {
  //     await fetchClientSecret();
  //     setShowCheckout(true);
  //   } catch (error) {
  //     if (retries > 0) {
  //       console.log(`Retrying payment... attempts left: ${retries}`);
  //       handlePayNow(retries - 1);
  //     } else {
  //       console.log("Payment failed after multiple attempts: ", error);
  //     }
  //   }
  // };

  



  return (
    <>
      <PlanNav />
      <div className="__fw" style={{ minHeight: "100dvh" }}>
        <div className="__mw">
          {/* <div className="addNew">
            {showVarifyNew && (
              <div className="addNew_in">
                <SuccessPay />
              </div>
            )}
          </div> */}

          <div className="temp_bok">
            <div className="temp_bok_head">
              <button
                onClick={handleBack}
                style={{ border: "none", background: "none" }}
              >
                <IoChevronBack
                  onClick={scrollToTop}
                  size={22}
                  style={{ marginRight: "20px" }}
                />
              </button>
              <h2>Request to Book</h2>
            </div>
            <div className="temp_book_wrp">
              <div className="temp_bok_lft">
                <div className="temp_bok_lft_in">
                  <h3>
                    <em>
                      {user?.fName} {user?.lName}{" "}
                    </em>
                    here your booking details
                  </h3>
                </div>
                <div className="lft_in_text">
                  <h4>Dates</h4>
                  <div className="lft_in_text_info">
                    <strong>CheckIn</strong> <em>{checkIn}</em>
                    <IoIosArrowRoundForward size={25} />
                    <strong>CheckOut </strong>
                    <em>{checkOut}</em>
                  </div>
                </div>
                <div className="lft_in_text">
                  <h4>Guests</h4>
                  <div className="lft_in_text_info">
                    <span> Adults</span> <span>{numberOfAdults}</span>
                    <span> Children</span>
                    <span>{numberOfChildren}</span>
                    <span> Infants</span>
                    <span>{numberOfInfants}</span>
                  </div>
                </div>
                <hr />
                <div className="lft_in_price">
                  <div className="temp_bok_lft_in_head">
                    <h2>Choose how to pay</h2>
                  </div>
                  <div className="left_price_data_all">
                    <div className="left_price_data">
                      <strong>Pay {totalPrice} now</strong>
                      <input
                        type="radio"
                        checked={selectPrice === totalPrice}
                        value={totalPrice}
                        onChange={priceCal}
                        name="totalPrice"
                      />
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className="left_price_data">
                      <div className="left_price_data_in_data">
                        <strong>Pay part now, part later</strong>
                        <span>{advance}, No extra fees</span>
                      </div>
                      <input
                        type="radio"
                        checked={selectPrice === advance}
                        value={advance}
                        onChange={priceCal}
                        name="advance"
                      />
                    </div>
                  </div>
                </div>
                {user?.role !== "guest" && user?.role !== "host" && (
                  <>
                    <div className="lft_in_price">
                      <div className="temp_bok_lft_in_head">
                        <h2>Log in or sign up to book</h2>
                      </div>
                      <div className="left_price_data_log">
                        <LoginBooking />
                      </div>
                    </div>
                    <div className="lft_in_price">
                      <div className=" lft_in_price_head">
                        <h3>Sign up</h3>
                      </div>
                      <div className="left_price_data_log">
                        <RegBooking />
                      </div>
                    </div>
                  </>
                )}

                <div>
                  <p>payment</p>
                </div>
              </div>
              <div className="temp_bok_rigt">
                <div className="temp_bok_rigt_in">
                  <div className="rigt_in_item_top">
                    <div className="item_top_img">
                      {data.photos?.[0] && (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${data.photos[0]}`}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="item_top_tex">
                      <small>{data.title}</small>
                      <small></small>
                    </div>
                  </div>
                  <hr />
                  <div className="rigt_in_item">
                    <h3>Price details</h3>
                    <div className="rigt_in_item_info">
                      <div className="item_info_in">
                        <span>
                          {data?.salePrice} X {totaldays} nights
                        </span>
                        <span>{totalPrice}</span>
                      </div>
                      <div className="item_info_in">
                        <span>Cleaning fee</span>
                        <span>Free</span>
                      </div>
                      <div className="item_info_in">
                        <span>LeisureryStays service fee </span>
                        <span>{serviceFee}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="rigt_in_item">
                    <div className="item_info_in">
                      <strong>Total</strong>
                      <strong>{price}</strong>
                    </div>
                    {user?.role === "guest" && (
                      <div className="pay_btn">
                        <button className="_btn" onClick={bookingPlace}>
                          Pay Now
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
