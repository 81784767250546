import React, { useContext, useEffect, useState } from "react";
import "./usersProfile.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";

const UsersProfile = () => {
  const { user } = useContext(AuthContext);
  const [guestBooking, setguestBooking] = useState([]);
//  const { bookingId } = useParams();
  // console.log(user)

  const userId = user?.id;
  // console.log("user:", userId);
  // console.log("bookingId", guestBooking);


 useEffect(() => {
   const cancelToken = axios.CancelToken.source();

   const fetchData = async () => {
     try {
       const response = await axios.get(`/guestBooking/${userId}`, {
         cancelToken: cancelToken.token,
       });
       setguestBooking(response.data);
     } catch (error) {
       if (axios.isCancel(error)) {
         console.log("Request canceled:", error.message);
       } else {
         console.error("Error fetching data:", error);
       }
     }
   };

   fetchData();

   return () => {
     cancelToken.cancel("Component unmounted or userId changed");
   };
 }, [userId]);

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="user">
          <div className="user_section">
            
            <div className="user_right">
              <div className="user_right_head">
                <h1>
                  welcome{" "}
                  <em>
                    {user.fName} {user.lName}{" "}
                  </em>
                </h1>
              </div>
              <div className="user_right_mnage">
                <h3>Manage your bookings</h3>
              </div>
              <hr />
              <div className="booking">
                {guestBooking.length > 0 &&
                  guestBooking.map((item) => (
                    <Link
                      key={item.bookingId}
                      to={`/booking/${userId}/${item.bookingId}/${item.placeId}`}
                    >
                      <div key={item.listingId} className="place_card">
                        <div className="place_img">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${item.placeImg}`}
                            alt=""
                          />
                        </div>
                        <div className="place_info">
                          <div className="place_one">
                            <h2>{item.placeName}</h2>
                          </div>

                          <div className="place_two">
                            <span>{item.placeCity}</span>
                          </div>
                          {/* <div className="place_three">
                            <span>{item.checkIn}</span> -{" "}
                            <span>{item.checkOut}</span>
                          </div> */}
                          <div className="place_four">
                            <span>
                              Rs. {item.price} <em>night</em>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersProfile;
