import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Itinerary from "../../../components/itinerary/Itinerary";
import "./addNewPlace.scss";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import HotelIcon from "@mui/icons-material/Hotel";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import MopedIcon from "@mui/icons-material/Moped";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import GroupsIcon from "@mui/icons-material/Groups";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import LocationInput from "../../../components/googleMap/LocationInput";
import MapView from "../../../components/googleMap/MapView";
import MapComponent from "../../../components/map/MapComponent";
import MarkerComponent from "../../../components/map/MarkerComponent";
import MyComponent from "../../../components/googleMap/MyComponent";
import { HiHome } from "react-icons/hi";

const AddNewPlace = () => {
  const { listingId, id } = useParams();

  const [selectListing, setSelectListing] = useState("");
  const [selectPlace, setSelectPlace] = useState("");
  const [city, setCity] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [beds, setBeds] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [shareBathrooms, setShareBathrooms] = useState(0);
  const [beadrooms, setBeadrooms] = useState(1);
  const [itinerary, setItinerary] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [photos, setPhotos] = useState([]);
  const [price, setPrice] = useState(100);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [houseRule, setHouseRule] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectHomeCategory, setSelectHome] = useState("");
  const [recStay, setRecStay] = useState("");
  const [cleanSafe, setCleanSafe] = useState("");

  const data = localStorage.getItem("user");
  const userData = JSON.parse(data);

  const [provinces] = useState([
    {
      name: "Colombo",
      cities: [
        "Mount Lavinia",
        "Piliyandala",
        "Borella",
        "Padukka",
        "Watareka",
        "Dehiwala",
        "Ratmalana",
        "Kolonnawa",
        "Kotte",
        "Kaduwela",
        "Awissawella",
        "Homagama",
        "Maharagama",
        "Kesbewa",
        "Moratuwa",
        "Waga",
        "Hanwella",
        "Battaramulla",
        "Colombo 1",
        "Colombo 2",
        "Colombo 3",
        "Colombo 4",
        "Colombo 5",
        "Colombo 6",
        "Colombo 7",
        "Colombo 8",
        "Colombo 9",
        "Colombo 10",
        "Colombo 11",
        "Colombo 12",
        "Colombo 13",
        "Colombo 14",
        "Colombo 15",
      ],
    },
    {
      name: "Gampaha",
      cities: [
        "Wattala",
        "Negombo",
        "Katana",
        "Divulapitiya",
        "Meerigama",
        "Minuwangoda",
        "Attanagalla",
        "Gampaha",
        "Ja-Ela",
        "Mahara",
        "Dompe",
        "Biyagama",
        "Kelaniya",
        "Katunayake",
      ],
    },
    {
      name: "Kalutara",
      cities: [
        "Agalawatta",
        "Panadura",
        "Bandaragama",
        "Horana",
        "Bulathsinhala",
        "Mathugama",
        "Wadduwa",
        "Beruwala",
        "Panadura",
        "Ingiriya",
      ],
    },
    {
      name: "Kandy",
      cities: [
        "Galagedara",
        "Hunnasgiriya",
        "Knuckles",
        "Hunnasgiriya",
        "Theldeniya",
        "Kundasale",
        "Gampola",
        "Nawalapitiya",
        "Digana",
        "Peradeniya",
        "Meemure",
      ],
    },
    {
      name: "Matale",
      cities: [
        "Dambulla",
        "Laggala",
        "Matale",
        "Raththota",
        "Sigiriya",
        "Dambulla",
        "Riverston",
        "Wasgamuwa",
      ],
    },
    {
      name: "Nuwara-Eliya",
      cities: [
        "Kithulgala",
        "Kotmale",
        "Ambewela",
        "Ohiya",
        "Hatton",
        "Maskeliya",
      ],
    },
    {
      name: "Galle",
      cities: [
        "Balapitiya",
        "Ambalangoda",
        "Karandeniya",
        "Elpitiya",
        "Hiniduma",
        "Baddegama",
        "Rathgama",
        "Akmeemana",
        "Habaraduwa",
        "Bentota",
        "Kosgoda",
        "Ahungalla",
        "Hikkaduwa",
        "Koggala",
        "Ahangama",
        "Talpe",
        "Unawatuna",
        "Neluwa",
      ],
    },
    {
      name: "Matara",
      cities: [
        "Deniyaya",
        "Hakmana",
        "Akuressa",
        "Kamburupitiya",
        "Devinuwara",
        "Polhena",
        "Weligama",
        "Mirissa",
        "Dikwella",
        "Urubokka",
        "Kotapola",
        "Morawaka",
        "Sinharaja",
      ],
    },
    {
      name: "Hambantota",
      cities: [
        "Mulkirigala",
        "Beliatta",
        "Tangalle",
        "Thissamaharamaya",
        "Walasmulla",
        "Ambalantota",
        "Ranna",
        "Rekawa",
        "Middeniya",
        "Ridiyagama",
        "Sooriyawewa",
        "Mattala",
        "Weerawila",
        "Situlpawwa",
        "Yala",
        "Kirinda",
      ],
    },
    {
      name: "Jaffna",
      cities: [
        "Kayts",
        "Waddukkoddai",
        "Kankasanthurai",
        "Manipay",
        "Kopay",
        "Uduppidi",
        "Point Pedro",
        "Chawakachcheri",
        "Nallur",
        "Jaffna",
        "Delft",
      ],
    },
    {
      name: "Mannar",
      cities: [
        "Vavuniya",
        "Silavathurai",
        "Madu",
        "Murunkan",
        "Thiruketheeswaram",
      ],
    },
    {
      name: "Batticaloa",
      cities: [
        "Vakari",
        "Kallady",
        "Paddiruppu",
        "Kattankudy",
        "Kalkudah",
        "Sallitheevu",
      ],
    },
    {
      name: "Ampara",
      cities: [
        "Samanthurai",
        "Kalmunai",
        "Pothuvil",
        "Dehiattakandiya",
        "Maduru Oya ",
        "Maha Oya",
        "Gal Oya",
        "Uhana",
        "Lahugala",
        "Oluvil",
        "Akkaraipattu",
        "Pottuvil",
        "Arugam Bay",
        "Panama",
      ],
    },
    {
      name: "Trincomalee",
      cities: [
        "Seruwil",
        "Mutur",
        "Pulmoddai",
        "Kumpurupiddi",
        "Nilaveli",
        "Kuchchaveli",
        "Kantale",
        "Seruwawila",
        "Somawathiya",
      ],
    },
    {
      name: "Kurunegala",
      cities: [
        "Galgamuwa",
        "Nikaweratiya",
        "Yapahuwa",
        "Hiriyala",
        "Wariyapola",
        "Panduwasnuwara",
        "Bingiriya",
        "Katugampola",
        "Kuliyapitiya",
        "Dambadeniya",
        "Polgahawela",
        "Kurunegala",
        "Mawathagama",
        "Dodangaslanda",
        "Melsiripura",
        "Ibbagamuwa",
      ],
    },
    {
      name: "Anuradhapura",
      cities: [
        "Wilpattu",
        "Horowpathana",
        "Tambuttegama",
        "Medawachchiya",
        "Avukana",
        "Siyambalangamuwa",
        "Habarana",
        "Hiriwadunna",
        "Mahawilachchiya",
        "Thanthirimale",
      ],
    },
    {
      name: "Polonnaruwa",
      cities: [
        "Minneriya",
        "Medirigiriya",
        "Kaudulla",
        "Girithale",
        "Dimbulagala",
      ],
    },
    {
      name: "Badulla",
      cities: [
        "Mahiyanganaya",
        "Passara",
        "Hali Ela",
        "Uva Paranagama",
        "Welimada",
        "Bandarawela",
        "Haputale",
        "Bandarawela",
        "Diyatalawa",
        "Boralanda",
        "Welimada",
        "Hakgala",
        "Pattipola",
        "Beragala",
        "Ella",
        "Bibile",
      ],
    },
    {
      name: "Moneragala",
      cities: [
        "Bibila",
        "Kataragama",
        "Wellawaya",
        "Udawalawe",
        "Buttala",
        "Sewanagala",
        "Gal Oya",
        "Lunugamvehera",
        "Siyambalanduwa",
      ],
    },
    {
      name: "Ratnapura",
      cities: [
        "Eheliyagoda",
        "Pelmadulla",
        "Balangoda",
        "Rakwana",
        "Nivithigala",
        "Kalawana",
        "Kolonna",
        "Balangoda",
        "Belihuloya",
        "Kuruwita",
        "Suriyakanda",
        "Embilipitiya",
        "Sinharaja",
      ],
    },
    {
      name: "Kegalle",
      cities: [
        "Dedigama",
        "Galigamuwa",
        "Rambukkana",
        "Mawanella",
        "Aranayaka",
        "Yatiyanthota",
        "Ruwanwella",
        "Deraniyagala",
      ],
    },
    {
      name: "Puttalam",
      cities: [
        "Kalpitiya",
        "Chilaw",
        "Marawila",
        "Wennappuwa",
        "Madampe",
        "Anamaduwa",
        "Wilpattu",
        "Thabbowa",
      ],
    },
    {
      name: "Kilinochchi",
      cities: [
        "Pooneryn",
        "Veravil",
        "Kumulamunai",
        "Chunnavil",
        "Iranamadu",
        "Vaddakkachchi",
        "Parantan",
        "Thadduvankoddi",
      ],
    },
    {
      name: "Mullaitivu",
      cities: ["Mankulam", "Alankulam", "Visuvamadu", "Welioya", "Chemmalai"],
    },
  ]);

  const handleProvinceChange = (event) => {
    const provinceName = event.target.value;
    setSelectedProvince(provinceName);
    // Find the selected province and update the filtered cities
    const selectedProvinceData = provinces.find(
      (province) => province.name === provinceName
    );
    if (selectedProvinceData) {
      setFilteredCities(selectedProvinceData.cities);
    } else {
      setFilteredCities([]);
    }
  };

  // ! select Listing
  const handleListingChange = (event) => {
    setSelectListing(event.target.value);
  };

  // ! select Place
  const handlePlaceChange = (event) => {
    setSelectPlace(event.target.value);
  };

  // ! select Place
  const handleHomeChange = (event) => {
    setSelectHome(event.target.value);
  };

  // ! Clean & Safe certification
  const handleSafeChange = (event) => {
    setCleanSafe(event.target.value);
  };

  // console.log("listingId", listingId);
  // console.log("id", id);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (!listingId) {
      return;
    }

    axios
      .get(`/getUpdatePlace/${id}/${listingId}`, {
        cancelToken: cancelToken.token,
      })
      .then((response) => {
        const { data } = response;
        setTitle(data.title);
        setPhotos(data.photos);
        setDescription(data.description);
        setItinerary(data.itinerary);
        setExtraInfo(data.extraInfo);
        setCheckIn(data.checkIn);
        setCheckOut(data.checkOut);
        setPrice(data.price);
        setSelectListing(data.selectListing);
        setSelectPlace(data.selectPlace);
        setCity(data.city);
        setBeds(data.beds);
        setBathrooms(data.bathrooms);
        setChildren(data.children);
        setAdults(data.adults);
        setInfants(data.infants);
        setCountry(data.country);
        setHouseRule(data.houseRule);
        setSelectedProvince(data.province);
        setAddress(data.address);
        setSelectHome(data.selectHomeCategory);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      });

    return () => {
      if (!cancelToken.token.reason) {
        cancelToken.cancel("Request canceled by cleanup");
      }
    };
  }, [listingId]);

  const navigate = useNavigate();

  async function uploadPhoto(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    await axios
      .post("/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setPhotos((prev) => {
          return [...prev, ...filenames];
        });
      });
  }

  const savePlace = async (e) => {
    e.preventDefault();

    const data = {
      id,
      selectListing,
      selectPlace,
      selectHomeCategory,
      city,
      adults,
      address,
      children,
      infants,
      beds,
      bathrooms,
      itinerary,
      title,
      photos,
      description,
      extraInfo,
      checkIn,
      checkOut,
      price,
      fName,
      lName,
      shareBathrooms,
      beadrooms,
      country,
      province: selectedProvince,
      houseRule,
      recStay,
      cleanSafe,
    };

    try {
      if (listingId) {
        // console.log(listingId);
        //update
        await axios.put(`/updatePlace/${id}/${listingId}`, {
          listingId,
          ...data,
        });
        navigate(`/hosting`);
      } else {
        //new place
        await axios.post("/addPlace", data);
        navigate(`/hosting`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function removePhoto(e, filename) {
    e.preventDefault();
    setPhotos([...photos.filter((photo) => photo !== filename)]);
  }

  function selectMainPhoto(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = photos.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setPhotos(newAddedPhotos);
  }
  // ! save name

  const fname = () => {
    const data = userData.fName;
    setFName(data);
  };
  const lname = () => {
    const data = userData.lName;
    setLName(data);
  };
  useEffect(() => {
    fname();
    lname();
  }, [savePlace]);

  // console.log(city, selectedProvince,country);

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="add_new_place">
          <div className="new_header">
            <div className="new_header_title">
              <h1>Welcome back, {userData.fName}</h1>
              <h2>Start a new listing</h2>
            </div>
            <div className="back_btn_con">
              <Link className="back_btn" to={`/hosting`}>
                <NavigateBeforeIcon />
                Back
              </Link>
            </div>
          </div>

          {/* owner = {id} <br />
          listingId = {listingId} */}
          <form onSubmit={savePlace}>
            <div className="form_in">
              <h2> 01. Which of these best describes your listing?</h2>
              <br />
              <div className="form_in_cat">
                <div className="cat_item">
                  <div>
                    <p>Stay</p>
                  </div>
                  <label>
                    <HotelIcon />
                    <input
                      type="radio"
                      value={"stay"}
                      name="stay"
                      checked={selectListing === "stay"}
                      onChange={handleListingChange}
                    />
                  </label>
                </div>
                {/* <div className="cat_item">
                  <div>
                    <p>Car</p>
                  </div>
                  <label>
                    <DirectionsCarIcon />
                    <input
                      value={"car"}
                      type="radio"
                      checked={selectListing === "car"}
                      name="car"
                      onChange={handleListingChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Bike</p>
                  </div>
                  <label>
                    <MopedIcon />
                    <input
                      type="radio"
                      checked={selectListing === "Bike"}
                      name="Bike"
                      value={"Bike"}
                      onChange={handleListingChange}
                    />
                  </label>
                </div> */}
              </div>
            </div>

            <div className="form_in">
              <h2>02. What type of place you have</h2>
              <br />
              <div className="form_in_cat">
                <div className="cat_item">
                  <div>
                    <p>Home</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "home"}
                      name="home"
                      value={"home"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Villa</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "villa"}
                      name="villa"
                      value={"villa"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Resort</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "resort"}
                      name="resort"
                      value={"resort"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Nature lodge</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "natureLodg"}
                      name="natureLodg"
                      value={"natureLodg"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Bungalow</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "bungalow"}
                      name="bungalow"
                      value={"bungalow"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Room</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "room"}
                      name="room"
                      value={"room"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Boutique Hotel</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "boutique"}
                      name="boutique"
                      value={"boutique"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Apartment</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "apartment"}
                      name="apartment"
                      value={"apartment"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Cabin</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "cabin"}
                      name="cabin"
                      value={"cabin"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Treehoues</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "treehoues"}
                      name="treehoues"
                      value={"treehoues"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>

                <div className="cat_item">
                  <div>
                    <p>Place to Stay</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "placeToStay"}
                      name="placeToStay"
                      value={"placeToStay"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>

                <div className="cat_item">
                  <div>
                    <p>Hut</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "hut"}
                      name="hut"
                      value={"hut"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>

                <div className="cat_item">
                  <div>
                    <p>Cottage</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "cottage"}
                      name="cottage"
                      value={"cottage"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Bed and Breakfast</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "bedAndBreakfast"}
                      name="bedAndBreakfast"
                      value={"bedAndBreakfast"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>

                <div className="cat_item">
                  <div>
                    <p>Townhoues</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "townhoues"}
                      name="townhoues"
                      value={"townhoues"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>

                <div className="cat_item">
                  <div>
                    <p>Vacation home</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "vacationHome"}
                      name="vacationHome"
                      value={"vacationHome"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>

                <div className="cat_item">
                  <div>
                    <p>Guesthoues</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "guesthoues"}
                      name="guesthoues"
                      value={"guesthoues"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
                <div className="cat_item">
                  <div>
                    <p>Hotel</p>
                  </div>
                  <label>
                    <input
                      type="radio"
                      checked={selectHomeCategory === "hotel"}
                      name="hotel"
                      value={"hotel"}
                      onChange={handleHomeChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="what_form_in">
              <h2>03. What type of place will guests have?</h2>
              <br />
              <div className="form_in_cat">
                <div className="_cat_item">
                  <div>
                    <h3>An entire place</h3>
                    <p>Guests have the whole place to themselves.</p>
                  </div>
                  <label className="label">
                    <HomeIcon />
                    <input
                      type="radio"
                      value={"entire_place"}
                      checked={selectPlace === "entire_place"}
                      name="entire_place"
                      onChange={handlePlaceChange}
                    />
                  </label>
                </div>
                <div className="_cat_item">
                  <div>
                    <h3>A room</h3>
                    <p>
                      Guests have their own room in a home, plus access to
                      shared spaces.
                    </p>
                  </div>
                  <label className="label">
                    <MeetingRoomIcon />
                    <input
                      value={"room"}
                      type="radio"
                      checked={selectPlace === "room"}
                      name="room"
                      onChange={handlePlaceChange}
                    />
                  </label>
                </div>
                <div className="_cat_item">
                  <div>
                    <h3>A shared room</h3>
                    <p>
                      Guests sleep in a room or common area that may be shared
                      with you or others.
                    </p>
                  </div>
                  <label className="label">
                    <GroupsIcon />
                    <input
                      value={"shared_room"}
                      type="radio"
                      checked={selectPlace === "shared_room"}
                      name="shared_room"
                      onChange={handlePlaceChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="form_in">
              <h2>04. Confirm your Location</h2>
              <p>
                Your address is only shared with guests after they’ve made a
                reservation.
              </p>

              <div className="form_in_cat">
                <div className="cat_item_city">
                  <p>category</p>
                  <div class="custom-select">
                    <select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="srilanka">Sri Lanka</option>
                      <option value="portugal">Portugal</option>
                    </select>
                  </div>
                </div>

                <div className="cat_item_city">
                  <p>Provinces</p>
                  <select
                    value={selectedProvince}
                    onChange={handleProvinceChange}
                  >
                    <option value="">Select</option>
                    {provinces.map((province, index) => (
                      <option key={index} value={province.name}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="cat_item_city">
                  <p>Filtered Cities:</p>
                  <select
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    {filteredCities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="cat_item_city">
                  <p>Address</p>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form_in">
              <h2>05. Share some basics about your place</h2>
              <br />
              <div className="form_in_cat">
                <div className="_cat_item">
                  <div>
                    <p>Adults</p>
                  </div>
                  <input
                    type="number"
                    placeholder="1"
                    min={1}
                    value={adults}
                    onChange={(e) => setAdults(e.target.value)}
                  />
                </div>
                <div className="_cat_item">
                  <div>
                    <p>Children</p>
                  </div>
                  <input
                    type="number"
                    placeholder="1"
                    min={0}
                    value={children}
                    onChange={(e) => setChildren(e.target.value)}
                  />
                </div>
                <div className="_cat_item">
                  <div>
                    <p>Infants</p>
                  </div>
                  <input
                    type="number"
                    placeholder="1"
                    min={0}
                    value={infants}
                    onChange={(e) => setInfants(e.target.value)}
                  />
                </div>
                <div className="_cat_item">
                  <div>
                    <p>Beds</p>
                  </div>
                  <input
                    type="number"
                    placeholder="1"
                    min={1}
                    value={beds}
                    onChange={(e) => setBeds(e.target.value)}
                  />
                </div>
                <div className="_cat_item">
                  <div>
                    <p>Bathrooms</p>
                  </div>
                  <input
                    type="number"
                    placeholder="1"
                    min={1}
                    value={bathrooms}
                    onChange={(e) => setBathrooms(e.target.value)}
                  />
                </div>
                <div className="_cat_item">
                  <div>
                    <p>Shared Bathroom</p>
                  </div>
                  <input
                    type="number"
                    placeholder="0"
                    min={0}
                    value={shareBathrooms}
                    onChange={(e) => setShareBathrooms(e.target.value)}
                  />
                </div>
                <div className="_cat_item">
                  <div>
                    <p>Beadrooms </p>
                  </div>
                  <input
                    type="number"
                    placeholder="1"
                    min={1}
                    value={beadrooms}
                    onChange={(e) => setBeadrooms(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* <section>
              <div>
                <h2>
                  <em>02</em>Tell guests what your place has to offer{" "}
                </h2>
              </div>
            </section> */}

            <div className="form_in">
              <h2>06. Tell guests what your place has to offer</h2>
              <p>You can add more amenities after you publish your listing.</p>
              <br />
              <Itinerary selected={itinerary} onChange={setItinerary} />
            </div>

            <div className="form_in">
              <h2>07. Add some photos of your house</h2>
              <p>
                You'll need 5 photos to get started. You can add more or make
                changes later.
              </p>
              <div>
                <div className="__input">
                  <div className="photo_grid">
                    {photos?.length > 0 &&
                      photos.map((link) => (
                        <div className="img_over" key={link}>
                          <img
                            src={
                              `${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/` +
                              link
                            }
                            alt=""
                          />
                          <div className="trash_icon">
                            <button onClick={(e) => removePhoto(e, link)}>
                              <DeleteOutlineIcon
                                style={{ fontSize: "medium" }}
                              />
                            </button>
                          </div>
                          <div className="star_icon">
                            <button onClick={(e) => selectMainPhoto(e, link)}>
                              {link === photos[0] && (
                                <StarIcon
                                  style={{
                                    fontSize: "medium",
                                    color: "var(--orange)",
                                  }}
                                />
                              )}
                              {link !== photos[0] && (
                                <StarBorderIcon
                                  style={{ fontSize: "medium" }}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      ))}
                    <label>
                      <input
                        onChange={uploadPhoto}
                        type="file"
                        multiple
                        className="btn_upload"
                      />
                      Upload
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form_in">
              <h2>08. Now, let's give your house a title</h2>
              <p>
                Short titles work best. Have fun with it—you can always change
                it later.
              </p>
              <div>
                <input
                  value={title}
                  type="text"
                  placeholder="Lush Private Penthouse With Ocean View & Gazebo"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="form_in">
              <h2>09. Create your description</h2>
              <p>Share what makes your place special.</p>
              <div className="__input">
                <textarea
                  placeholder="Have fun with the whole family at this stylish place."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="form_in">
              <h2>10.Extra info</h2>
              <br />
              {/* <p>House rules, etc</p> */}
              <div className="__input">
                <textarea
                  placeholder="Have fun with the whole family at this stylish place."
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="form_in">
              <h2>11. House rules</h2>
              <br />
              {/* <p>House rules, etc</p> */}
              <div className="__input">
                <textarea
                  placeholder="Quiet Hours: Please respect quiet hours from [insert time] to [insert time] to ensure a peaceful environment for all guests."
                  value={houseRule}
                  onChange={(e) => setHouseRule(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="form_in">
              <h2>12. Now, set your price</h2>
              <p>You can change it anytime.</p>
              <div className="_item">
                <input
                  placeholder="4500.00 LKR"
                  className="price"
                  value={price}
                  min={1}
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>

            <div className="form_in">
              <h2>13. Add, check in & out times</h2>
              <p>
                add check in and out times, remember to have some time window
                for cleaning the room between guests
              </p>
              <div className="check_in">
                <div className="_item">
                  <h3>Check in time</h3>
                  <br />
                  <input
                    value={checkIn}
                    type="Time"
                    placeholder="14"
                    onChange={(ev) => setCheckIn(ev.target.value)}
                  />
                </div>
                <div className="_item">
                  <h3>Check out time</h3>
                  <br />
                  <input
                    value={checkOut}
                    type="Time"
                    onChange={(ev) => setCheckOut(ev.target.value)}
                    placeholder="11"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="form_in">
              <h2>14. Overload recommended place </h2>
              <p>
                If our place is overbooked, we're happy to suggest an
                alternative owned by a friend place
              </p>
              <div className="__input">
                <textarea
                  value={recStay}
                  placeholder="
                  Name: Serene Retreat Guesthouse 
Address: 123 Tranquil Lane, Cityville, State, Zip Code 
Contact: Phone: (555) 123-4567, Email: info@sereneretreatguesthouse.com"
                  onChange={(e) => setRecStay(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="form_in">
              <h2>15. Clean & Safe certification</h2>
              <p>
                You're safe with us. Take a deep breath and enjoy. We want to
                make sure your stay is safe and unforgettable.Enjoy Sri lanka
                and all its beauty in safety.
              </p>
              <div className="check_in">
                <label>
                  <h3>Yes</h3>
                  <input
                    type="radio"
                    value={"no"}
                    name="no"
                    checked={cleanSafe === "no"}
                    onChange={handleSafeChange}
                  />
                </label>
                <label>
                  <h3>No</h3>
                  <input
                    type="radio"
                    value={"yes"}
                    name="yes"
                    checked={cleanSafe === "yes"}
                    onChange={handleSafeChange}
                  />
                </label>
              </div>
            </div>
            <div className="saveBtn">
              <button className="__btn">Save & exit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewPlace;
