import React from 'react'
import { Link } from 'react-router-dom';
import ClearIcon from "@mui/icons-material/Clear";
import LoginAccout from '../../components/LoginAccount/LoginAccount';
import "./login.scss";

const Login = () => {
  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="bg-tex">
          <div class="ls-Background-background">
            <div class="ls-ls-Background-background"></div>
            <div
              class="ls-Background-background-s-left"
              style={{ background: "#0073ff" }}
            ></div>
            <div
              class="ls-Background-background-f-s-rihgt"
              style={{ background: "#ffb700" }}
            ></div>
          </div>
        </div>
        <div className="login">
          <div className="_log_in">
            <>
              <div className="_in_h">
                <div className="_in_h">
                  <img src={require("../../img/bk_logo.png")} alt="" />
                  <p>
                    <em>Sign in</em>
                  </p>
                </div>
              </div>
              <LoginAccout />
              <div className="_rg_pg">
                <span>
                  Don't have an account yet?
                  <Link style={{ color: "#40c0f0" }} to={"/register"}>
                    {" "}
                    Register now
                  </Link>
                </span>
              </div>
            </>
            <div className="cross_mark">
              <div className="_icon">
                <Link to={"/"}>
                  <ClearIcon className="icon" fontSize="20" color="black" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login
