import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  lazy,
  Suspense,
  startTransition,
} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import "./singalPlace.scss";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { differenceInCalendarDays, format } from "date-fns";
import { AuthContext } from "../../context/AuthContext";
import useFetch from "../../hooks/useFetch";
import { DateRange } from "react-date-range";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { IoPeopleSharp } from "react-icons/io5";
import { MdLocalHotel } from "react-icons/md";
import { FaBath } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FaStar } from "react-icons/fa6";
import GoogleMapReact from "google-map-react";
import Marker from "../../components/marker/Marker";
import CardSkeleton from "../../components/cardSkeleton/CardSkeleton";
const PageSkeleton = lazy(() =>
  import("../../components/singalPageSkeleton/PageSkeleton")
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SingalPlace = () => {
  const query = useQuery();
  const numberOfAdults = query.get("numberOfAdults");
  const numberOfChildren = query.get("numberOfChildren");
  const numberOfInfants = query.get("numberOfInfants");
  const checkIn = query.get("checkIn");
  const checkOut = query.get("checkOut");

  const bookingData = useLocation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAtiZiUrczEpBtb5VDXd_Wj2X66FRN6J3M",
  });

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { listingId } = useParams();

  const [price, setPrice] = useState(0);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [children, setChildren] = useState(numberOfChildren || 0);
  const [adults, setAdults] = useState(numberOfAdults || 1);
  const [infants, setInfants] = useState(numberOfInfants || 0);
  const [openDate, setOpenDate] = useState(false);
  const [totaldays, setTotalDays] = useState(0);
  const [dates, setDates] = useState([
    {
      startDate: checkIn ? new Date(checkIn) : new Date(),
      endDate: checkOut ? new Date(checkOut) : new Date(),
      key: "selection",
    },
  ]);

  const { data, error, refetch } = useFetch(`/singalPlace/${listingId}`);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(null);
  const [profile, setProfile] = useState([]);
  const [guestBooking, setGuestBooking] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, [data]);

  useEffect(() => {
    const diffDays = calculateDateDifference(
      dates[0].startDate,
      dates[0].endDate
    );
    setTotalDays(diffDays);
  }, [dates]);

 useEffect(() => {
   const ownerId = data?.owner;
   if (ownerId) {
     axios
       .get(`/profile/${ownerId}`)
       .then((response) => {
         startTransition(() => {
           setProfile(response.data);
         });
       })
       .catch((error) => {
         console.log(error);
       });
   }
 }, [data]);

  useEffect(() => {
    const fetchLocation = async () => {
      const address = `${data?.address},${data?.city},${data?.province},${data?.country}`;
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyAtiZiUrczEpBtb5VDXd_Wj2X66FRN6J3M`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setLocation({ lat, lng });
        } else {
          throw new Error("No results found");
        }
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    if (data) fetchLocation();
  }, [data]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/guestSingalBookingCheckInCheckOut/${data?.owner}/${listingId}`,
          { cancelToken: cancelToken.token }
        );
        setGuestBooking(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error:", error.message);
        }
      }
    };

    if (data) fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [data, listingId]);

  useEffect(() => {
    if (guestBooking && Array.isArray(guestBooking)) {
      const getDatesBetween = (startDate, endDate) => {
        const datesArray = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          datesArray.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return datesArray;
      };

      const disabledDatesArray = guestBooking.flatMap((item) =>
        getDatesBetween(new Date(item.checkIn), new Date(item.checkOut))
      );
      setDisabledDates(disabledDatesArray);
    }
  }, [guestBooking]);

  const address =
    data?.address +
    "," +
    data?.city +
    " ," +
    data?.province +
    "," +
    data?.country;

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const ratings = data?.guestReview?.flatMap((item) => item.selectRating) || [];
  const sumOfRatings = ratings.reduce(
    (acc, currentValue) => acc + parseInt(currentValue),
    0
  );
  const indexOfRating = ratings.length;

  const avgRating = () => {
    const rating = sumOfRatings / (5 * indexOfRating);
    return rating;
  };

  const totalAvgRating = () => {
    if (isNaN(avgRating())) {
      return 0;
    }
    return avgRating().toFixed(2);
  };

  // const handleListingChange = (event) => {
  //   const numericValue = parseFloat(event.target.value);
  //   setPrice(numericValue);
  // };


  const hostId = data.owner;

  
  const serviceFee = 99;
  const totalPrice = data?.salePrice * totaldays;
  const subTotal = data?.salePrice * totaldays + serviceFee;
  const advance = (totalPrice * 25) / 100;
  const advanceSubTotal = advance + serviceFee;


  if (loading) {
    return <PageSkeleton />;
  }

  if (showAllPhotos) {
    return (
      <div className="show_all_pic">
        <div className="show_in">
          <h2>Photo of - {data?.title}</h2>
          <div className="close_btn">
            <span onClick={() => setShowAllPhotos(false)}>
              <ArrowBackIosIcon />
            </span>
          </div>
          {data?.photos?.length > 0 &&
            data.photos.map((addedPhotos, index) => (
              <div key={index}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${addedPhotos}`}
                  alt=""
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  const generateBookingLink = () => {
    const queryParams = new URLSearchParams({
      checkin: format(checkIn, "yyyy-MM-dd"),
      checkout: format(checkOut, "yyyy-MM-dd"),
      numberOfAdults: adults,
      numberOfChildren: children,
      numberOfInfants: infants,
      listingId: listingId,
    }).toString();
    return `/book/stays?${queryParams}`;
  };

  return (
    <div className="__fw" style={{ minHeight: "100dvh" }}>
      <div className="__mw">
        <div className="singal_place">
          <div className="head">
            <h1>{data.title}</h1>
          </div>

          <div className="_image_gallry">
            <div className="image_grid_main">
              <div className="grid_one ">
                {data.photos?.[0] && (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${data.photos[0]}`}
                    alt=""
                  />
                )}
              </div>
              <div className=" grid_two">
                <div className="grid_two_main">
                  <div className="grid_two_in">
                    {data.photos?.[0] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${data.photos[1]}`}
                        alt=""
                      />
                    )}
                  </div>

                  <div className="grid_two_in">
                    {data.photos?.[0] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${data.photos[2]}`}
                        alt=""
                      />
                    )}
                    {/* <div className="rating">
                      <h3>★ {totalAvgRating}</h3>
                    </div> */}
                  </div>
                </div>
                <div className="grid_two_main">
                  <div className="grid_two_in">
                    {data.photos?.[0] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${data.photos[3]}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="grid_two_in">
                    {data.photos?.[0] && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_UPLOAD_LINK}/${data.photos[4]}`}
                        alt=""
                      />
                    )}
                    <span onClick={() => setShowAllPhotos(true)}>
                      <div className="more_pic">
                        <AppsIcon style={{ fontSize: "medium" }} />{" "}
                        <span>Show all photos</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="place_info_wrap">
            <div className="place_info_a">
              <div className="location">
                <div className="head_title">
                  <div className="_location">
                    <h2>{data.city + "," + " " + data.province}</h2>
                    <div className="_room_info">
                      <IoPeopleSharp />
                      {data.adults + data.children} <span> guests</span>{" "}
                      <em>•</em>
                      <MdLocalHotel />
                      {data.beds}
                      <span>beds</span> <em>•</em> <FaBath />
                      {data.bathrooms} <span>bath</span>
                    </div>
                    <div className="ra_star">
                      <FaStar />
                      {totalAvgRating()}
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
              <div className="home_owner">
                <div className="ownerLogo">
                  <div className="ownerLogo_img">
                    <img
                      src={
                        `${process.env.REACT_APP_DOC_UPLOAD_LINK}/` +
                        profile.docPhotos
                      }
                      alt=""
                    />
                  </div>
                  {profile.certify === "certify" && (
                    <div className="certify">
                      <img src={require("../../img/varify.png")} alt="" />
                    </div>
                  )}
                </div>
                <div className="owner_info">
                  <Link
                    to={`/about-host/${listingId}/${hostId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{profile.displayName}</span>
                    <span>Superhost 4 years hosting</span>
                  </Link>
                </div>
              </div>
              <hr style={{ marginTop: "20px", marginBottom: "40px" }} />
              <div className="amenities">
                <div>
                  <h2>What this place offers</h2>
                </div>
                <div className="icons">
                  {data.itinerary?.length > 0 &&
                    data.itinerary.map((addedPhotos, index) => (
                      <div className="icon" key={index}>
                        <div>
                          <IoCheckmarkCircleOutline color="#0068CF" />
                        </div>
                        <div>
                          <span>{addedPhotos}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
              <div className="description">
                <h3>About this space</h3>
                <p>{data.description}</p>
              </div>

              <div className="description">
                <h3>Things to know</h3>
                <p>{data.extraInfo}</p>
              </div>

              <div className="description">
                <div className="description_wrap">
                  <h3>House rules</h3>
                  <p>{data.houseRule}</p>
                </div>
              </div>
            </div>

            <div className="place_info_b">
              <div className="place_info_b_in">
                <div className="open_date">
                  <div className="open_date_head">
                    <h3> Select check-in date</h3>
                    <small>Add your travel dates for exact pricing</small>
                  </div>
                  <DateRange
                    className="dateRange"
                    disabledDates={disabledDates.flat()}
                    onChange={(item) => setDates([item.selection])}
                    minDate={new Date()}
                    ranges={dates}
                  />
                </div>
                <hr />
                <form>
                  <div className="place_date_main">
                    <div className="__price">
                      <h2>
                        <em>Rs.</em>
                        {data.salePrice}
                      </h2>
                      <small>Avg price per night</small>
                    </div>

                    <div className="__date">
                      <div className="date_in">
                        <div className="_check__in ">
                          <div className="item">
                            <span>Check In </span>
                          </div>
                          <div className="item">
                            <span> </span>
                          </div>
                          <div className="item">
                            <span>Check Out</span>
                          </div>
                        </div>
                        <div className="date_wrap">
                          <div
                            className="__ch_date"
                            onClick={() => setOpenDate(!openDate)}
                          >
                            {" "}
                            {`${format(
                              dates[0].startDate,
                              "dd/MM/yyyy"
                            )}   -  to  -  ${format(
                              dates[0].endDate,
                              "dd/MM/yyyy"
                            )}`}
                          </div>
                          {/* {openDate && (
                          <div className="open_date">
                            <DateRange
                              disabledDates={disabledDates.flat()}
                              onChange={(item) => setDates([item.selection])}
                              minDate={new Date()}
                              ranges={dates}
                            />
                          </div>
                        )} */}
                        </div>
                      </div>
                      <div className="geust_in">
                        <div className="geust_in_">
                          <div className="__check ">
                            <span>Adults</span> <br />
                            <small>Age 13+</small>
                          </div>
                          <div className="quantity">
                            <div className="__pls_btn">
                              <div className="pls_btn">
                                <span
                                  onClick={() =>
                                    setAdults((prev) =>
                                      prev === 1 ? 1 : prev - 1
                                    )
                                  }
                                >
                                  -
                                </span>
                              </div>
                            </div>
                            <div className="pls_btn_">
                              <h3>{adults}</h3>
                            </div>
                            <div className="__pls_btn">
                              <div className="pls_btn">
                                <span
                                  onClick={() => {
                                    if (adults < data.adults) {
                                      setAdults((prev) => prev + 1);
                                    }
                                  }}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="geust_in_">
                          <div className="__check ">
                            <span>Children</span> <br />
                            <small>Ages 2–12</small>
                          </div>
                          <div className="quantity">
                            <div className="__pls_btn">
                              <div className="pls_btn">
                                <span
                                  onClick={() =>
                                    setChildren((prev) =>
                                      prev > 0 ? prev - 1 : 0
                                    )
                                  }
                                >
                                  -
                                </span>
                              </div>
                            </div>
                            <div className="pls_btn_">
                              <h3>{children}</h3>
                            </div>
                            <div className="__pls_btn">
                              <div className="pls_btn">
                                <span
                                  onClick={() => {
                                    if (children < data.children) {
                                      setChildren((prev) => prev + 1);
                                    }
                                  }}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="geust_in_">
                          <div className="__check ">
                            <span>Infants</span> <br />
                            <small>Under 2</small>
                          </div>
                          <div className="quantity">
                            <div className="__pls_btn">
                              <div className="pls_btn">
                                <span
                                  onClick={() =>
                                    setInfants((prev) =>
                                      prev > 0 ? prev - 1 : 0
                                    )
                                  }
                                >
                                  -
                                </span>
                              </div>
                            </div>
                            <div className="pls_btn_">
                              <h3>{infants}</h3>
                            </div>
                            <div className="__pls_btn">
                              <div className="pls_btn">
                                <span
                                  onClick={() => {
                                    if (infants < data.infants) {
                                      setInfants((prev) => prev + 1);
                                    }
                                  }}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="book_now">
                      <Link to={generateBookingLink()}>
                        <button onClick={scrollToTop} className="_button">
                          Reserve
                        </button>
                      </Link>
                    </div>

                    <div className="total_price">
                      <div className="total_head">
                        <span>Pick the dates to check total costs</span>
                      </div>
                      <div className="sub_total">
                        <span>
                          Rs.{data.salePrice}
                          <em>
                            {" "}
                            <IoClose />{" "}
                          </em>{" "}
                          {totaldays}
                          <small>Nights</small>
                        </span>
                        <span> Rs. {totalPrice?.toFixed(2)}</span>
                      </div>

                      <div className="sub_total">
                        <span>Service fee </span>
                        <span>Rs. {serviceFee?.toFixed(2)}</span>
                      </div>
                      <hr />
                      <div className="totalCost">
                        <div className="totalCost_in">
                          <div className="totalCost_info">
                            <h3>Total</h3>
                            <h3>Rs. {subTotal?.toFixed(2)}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <hr style={{ marginTop: "20px", marginBottom: "30px" }} />
          <div className="_map_head">
            <h3>Where you’ll be</h3>
            <p>{data.city + "," + " " + data.province}</p>
          </div>
          <div className="_map" style={{ width: "100%" }}>
            <div style={{ height: "400px", width: "100%" }}>
              {loading ? (
                <div>Loading...</div>
              ) : (
                location && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAtiZiUrczEpBtb5VDXd_Wj2X66FRN6J3M",
                    }}
                    center={{ lat: location.lat, lng: location.lng }}
                    zoom={17}
                    draggable={false} // Disable dragging of the map
                    options={() => {
                      return {
                        zoomControlOptions: {
                          position: window.google.maps.ControlPosition.TOP_LEFT, // Accessing google through window object
                        },
                      };
                    }}
                  >
                    <Marker
                      lat={location.lat}
                      lng={location.lng}
                      text={address}
                      draggable={false}
                    />
                  </GoogleMapReact>
                )
              )}
            </div>
          </div>

          <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
          <div className="review_wrap">
            <div className="review_wrap_in">
              {data.guestReview?.length > 0 &&
                data.guestReview?.map((item, index) => (
                  <div className="review_card" key={index}>
                    <div className="review_card_head">
                      <div className="review_card_img">
                        <img src={require("../../img/profile.jpeg")} alt="" />
                      </div>
                      <div className="review_card_info">
                        <h3>{item.guestName}</h3>
                        <span>{item.guestCountry}</span>
                      </div>
                    </div>
                    <div className="rating_wrap">
                      <div className="rating_wrap_rating">
                        {Array.from({ length: 5 }, (_, i) => (
                          <p key={i + 1}>
                            {i + 1 <= item.selectRating ? "★" : ""}
                          </p>
                        ))}
                      </div>
                      <div className="_time">
                        <span>1 week ago</span>
                      </div>
                      <div className="_stay">
                        <span>Stayed a few nights</span>
                      </div>
                    </div>
                    <div className="guest_comment">
                      <p>{item.comment}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingalPlace;
